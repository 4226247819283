import React from "react";
import { NavLink } from "react-router-dom";

const AdminSidebarMenuItem = (props) => {
  const Icon = props.icon;
  return (
    <NavLink
      to={props.target}
      activeStyle={{
        backgroundColor: "rgba(255, 255, 255, 0.5)",
      }}
      style={{
        textDecoration: "none",
        paddingTop: 16,
        paddingBottom: 16,
      }}
      exact
    >
      <Icon
        style={{
          width: 20,
          height: 20,
          marginLeft: 15.5,
        }}
      />
      <span
        style={{
          fontWeight: "bold",
          fontSize: 14,
          color: "#fff",
          marginLeft: 10.5,
        }}
      >
        {props.text}
      </span>
    </NavLink>
  );
};

export default AdminSidebarMenuItem;
