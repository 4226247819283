import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { XAxis, YAxis, LineMarkSeries, FlexibleWidthXYPlot } from "react-vis";

import statisticAction from '../../store/actions/statisticAction'

const DevelopmentSymptom = (props) => {

  const {
    levels,
    chosenQuestion,
    chosenPain,
    changeQuestion,
    changePain,
    developmentSymptoms,
    fetchDevelopmentSymptoms,
    selectedApp,
  } = props

  const [questionText, setQuestionText] = useState('Har du smärta?')

  const selectQuestion = useRef(null)
  const selectPain = useRef(null)

  useEffect( ()=> {
    if (chosenQuestion ) {
      fetchDevelopmentSymptoms(chosenQuestion, chosenPain)
    }
  }, [chosenPain, chosenQuestion, selectedApp])

  useEffect( () => {
    if (selectedApp === "YLVA") {
      setQuestionText('Har du smärta?')
    } else {
      setQuestionText('Mår du bra?')
    }
  }, [selectedApp])

  useEffect(() => {
    selectQuestion.current.selectedIndex = 0
    selectPain.current.selectedIndex = 0
  }, [levels])

  const painChanger = (e) => {
    e.persist()
    changePain(e.target.value)
  }

  const questionChanger = (e) => {
    e.persist()
    changeQuestion(e.target.value)
    if (selectedApp === 'YLVA') {
      switch(e.target.value) {
        case "1" :
          setQuestionText('Har du smärta?')
          break;
    
        case "2" :
          setQuestionText('Har du urinläckage?')
          break;
    
        case "3" :
          setQuestionText('Har du ont i underlivet?')
          break ;
    
        case "4" :
          setQuestionText("Har du smärta vid samlag?")
          break ;
    
        default:
          setQuestionText('Har du smärta?')
      }
    } else {
        switch(e.target.value) {
          case "1" :
            setQuestionText('Mår du bra?')
            break;
      
          case "2" :
            setQuestionText('Sover du bra?')
            break;
      
          case "3" :
            setQuestionText('Har du ont när du går och står?')
            break ;
      
          case "4" :
            setQuestionText("Har du ont när du vilar?")
            break ;
      
          default:
            setQuestionText('Mår du bra?')
        }
    }
  }

  return (
    <div className="col-md-6 pl-0">
      <div
        className="d-flex flex-column"
        style={{
          boxShadow: "0px 0px 3.64313px rgba(0, 0, 0, 0.25)",
        }}
      >
        <div className="d-flex flex-row justify-content-between align-items-start py-3 px-3 mb-4">
          <div className="d-flex flex-column px-0">
            <span
              style={{
                fontWeight: 600,
                fontSize: 18,
              }}
            >
              Utveckling Symtom
            </span>
            <span
              style={{
                fontWeight: "normal",
                fontSize: 15,
              }}
            >
              {questionText}
            </span>
          </div>

          <div className="d-flex flex-column col-md-5 px-0">
            <select
              ref={selectQuestion}
              onChange={questionChanger}
              className="form-control mb-2"
              style={{
                color: "#000",
              }}
            >
              <option value={1}>Fråga 1</option>
              <option value={2}>Fråga 2</option>
              <option value={3}>Fråga 3</option>
              <option value={4}>Fråga 4</option>
            </select>

            <select
              ref={selectPain}
              onChange={painChanger}
              className="form-control"
              style={{
                color: "#000",
              }}
            >
              <option value={1}>Nej</option>
              <option value={2}>Lite</option>
              <option value={3}>Ganska</option>
              <option value={4}>Mycket</option>
            </select>
          </div>
        </div>
        <div className="container">
          {!developmentSymptoms ? 'loading..' :
            <FlexibleWidthXYPlot
              height={265}
              colorType="literal"
              xType="ordinal"
              yType="linear"
              yDomain={[0, 100]}
              margin={{ left: 70, right: 10, top: 10, bottom: 40 }}
            >
              <LineMarkSeries
                data={developmentSymptoms}
                strokeStyle="solid"
                color="#930000"
                animation
              />
              <XAxis />
              <YAxis tickFormat={tick => tick+'%'} />
            </FlexibleWidthXYPlot>
          }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    levels: state.statistic.levels,
    developmentSymptoms: state.statistic.developmentSymptoms,
    chosenQuestion: state.statistic.symptomsChosenQuestion,
    chosenPain: state.statistic.symptomsChosenPain,
    selectedApp: state.statistic.selectedApplication
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDevelopmentSymptoms: (questionNumber, painNumber) => {
      dispatch(statisticAction.fetchDevelopmentSymptoms(questionNumber, painNumber))
    },
    changeQuestion: (questionNumber) => {
      dispatch(statisticAction.changeSymptomsQuestion(questionNumber))
    },
    changePain: (painNumber) => {
      dispatch(statisticAction.changeSymptomsPain(painNumber))
    },
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(DevelopmentSymptom);
