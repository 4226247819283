/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import exerciseListAction from "../../store/actions/exerciseListAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import ExerciseItem from "./ExerciseItem";
import CustomInputFile from "./CustomInputFile";
import Swal from "sweetalert2";

const ModalTitle = styled(Modal.Title)`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #000;
`;

const LabelText = styled.span`
  font-size: 15;
  font-weight: normal;
  font-style: normal;
  color: #000;
`;

const ModalFormSelect = styled.select`
  color: #000;
  font-size: 18px;
`;

const ButtonSubmit = styled.input`
  color: #fff;
  background-color: #930000;
  border-radius: 17px;
  text-align: center;
  height: 35px;
  width: 200px;
  font-size: 15px;
  font-weight: bold;
  outline: none;
`;

const TextAreaInput = styled.textarea`
  width: 60%;
`;

const ExerciseBankContainer = styled.div`
  box-shadow: 0px 0px 3.64313px rgba(0, 0, 0, 0.25);
`;

const ExerciseBankTitle = styled.span`
  font-weight: 600;
  font-size: 18.2px;
  line-height: 26px;
`;

const TextAddExercise = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 18.2px;
  line-height: 26px;
  margin-right: 20px;
`;

const ExercisesListContainer = (props) => {
  const {
    selectedApplication,
    exerciseList,
    fecthExerciseList,
    addExercise,
  } = props;

  // Modal Add Exercise Data
  const [showModalAddExercise, setShowModalAddExercise] = useState(false);

  const handleClose = () => {
    clearModal();
    setShowModalAddExercise(false);
    setTimeout(() => {
      fecthExerciseList()
    }, 5000)
  };
  const handleShow = () => setShowModalAddExercise(true);

  const [name, setName] = useState("");
  const [activeTimeSec, setActiveTimeSec] = useState(0);
  const [activeTimeMin, setActiveTimeMin] = useState(0);
  const [restTimeSec, setRestTimeSec] = useState(0);
  const [restTimeMin, setRestTimeMin] = useState(0);
  const [repetition, setRepetition] = useState("");
  const [description, setDescription] = useState("");
  const [audioFile, setAudioFile] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [thumbnailFile, setThumbnailFile] = useState(null);

  const submitHandle = (e) => {
    e.preventDefault();
    console.log(activeTimeSec, activeTimeMin)
    if (activeTimeSec == 0 && activeTimeMin == 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Active time must be above 0 second.',
      })
    } else if (restTimeMin == 0 && restTimeSec == 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Rest time must be above 0 second.',
      })
    } else {
      const submitData = {
        application: selectedApplication,
        name: name,
        activeTimeSec: activeTimeSec,
        activeTimeMin: activeTimeMin,
        restTimeSec: restTimeSec,
        restTimeMin: restTimeMin,
        repetition: repetition,
        description: description,
        audio: audioFile,
        video: videoFile,
        thumbnail: thumbnailFile,
      };
      
      addExercise(submitData, handleClose, fecthExerciseList);
      fecthExerciseList(selectedApplication);
    }
  };

  const clearModal = () => {
    setName("");
    setActiveTimeSec(0);
    setActiveTimeMin(0);
    setRestTimeSec(0);
    setRestTimeMin(0);
    setRepetition("");
    setDescription("");
    setAudioFile(null);
    setVideoFile(null);
    setThumbnailFile(null);
  };

  useEffect(() => {
    fecthExerciseList(selectedApplication);
  }, [selectedApplication, showModalAddExercise]);

  return (
    <>
    <style type='text/css'>
      {`
        .close {
          outline: none !important;
        }
      `}
    </style>
      {/* Modal Add Exercise */}
      <Modal show={showModalAddExercise} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <ModalTitle>Lägg till övning</ModalTitle>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12">
              <form onSubmit={submitHandle}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Namn"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  required
                />
                <div className="d-flex flex-row my-3 justify-content-between align-items-center">
                  <LabelText>Aktiv tid</LabelText>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}>
                    <ModalFormSelect
                      className="form-control"
                      defaultValue={activeTimeMin}
                      onChange={(e) => setActiveTimeMin(e.target.value)}
                      style={{
                        width: '7.6vw',
                      }}
                    >
                      <option value={0} >
                        0 Min
                      </option>
                      {Array.from(Array(59), (e, i) => {
                        return (
                          <option key={i+1} value={i+1}>
                            {i+1} Min
                          </option>
                        );
                      })}
                    </ModalFormSelect>
                    <ModalFormSelect
                      className="form-control"
                      defaultValue={activeTimeSec}
                      onChange={(e) => setActiveTimeSec(e.target.value)}
                      style={{
                        width: '7.6vw',
                        marginLeft: '1vw'
                      }}
                    >
                      <option value={0}>
                        0 Sec
                      </option>
                      {Array.from(Array(59), (e, i) => {
                        return (
                          <option key={i+1} value={i+1}>
                            {i+1} Sec
                          </option>
                        );
                      })}
                    </ModalFormSelect>
                  </div>
                </div>
                <div className="d-flex flex-row my-3 justify-content-between align-items-center">
                  <LabelText>Vilotid</LabelText>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}>
                    <ModalFormSelect
                      className="form-control"
                      defaultValue={restTimeMin}
                      onChange={(e) => setRestTimeMin(e.target.value)}
                      style={{
                        width: '7.6vw',
                      }}
                    >
                      <option value={0} >
                        0 Min
                      </option>
                      {Array.from(Array(59), (e, i) => {
                        return (
                          <option key={i+1} value={i+1}>
                            {i+1} Min
                          </option>
                        );
                      })}
                    </ModalFormSelect>
                    <ModalFormSelect
                      className="form-control"
                      defaultValue={restTimeSec}
                      onChange={(e) => setRestTimeSec(e.target.value)}
                      style={{
                        width: '7.6vw',
                        marginLeft: '1vw'
                      }}
                    >
                      <option value={0} >
                        0 Sec
                      </option>
                      {Array.from(Array(59), (e, i) => {
                        return (
                          <option key={i+1} value={i+1}>
                            {i+1} Sec
                          </option>
                        );
                      })}
                    </ModalFormSelect>
                  </div>
                </div>
                <div className="d-flex flex-row my-3 justify-content-between align-items-center">
                  <LabelText>Repititioner</LabelText>
                  <ModalFormSelect
                    className="form-control col-md-3"
                    defaultValue={repetition}
                    onChange={(e) => setRepetition(e.target.value)}
                    required
                  >
                    <option value="" disabled hidden>
                      Antal
                    </option>
                    {Array.from(Array(90), (e, i) => {
                      return (
                        <option key={i + 1} value={i + 1}>
                          {i + 1}
                        </option>
                      );
                    })}
                  </ModalFormSelect>
                </div>
                <div className="d-flex flex-row my-3 justify-content-between align-items-center">
                  <LabelText>Beskrivning</LabelText>
                  <TextAreaInput
                    className="form-control"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                  ></TextAreaInput>
                </div>
                <div className="d-flex flex-row my-3 justify-content-between align-items-center">
                  <LabelText>Ljudfil</LabelText>
                  <CustomInputFile
                    fileType="audio"
                    setFile={setAudioFile}
                    file={audioFile}
                    accept="audio/mpeg"
                  />
                </div>
                <div className="d-flex flex-row my-3 justify-content-between align-items-center">
                  <LabelText>Videoklipp</LabelText>
                  <CustomInputFile
                    fileType="video"
                    setFile={setVideoFile}
                    file={videoFile}
                    accept="video/mp4"
                  />
                </div>
                <div className="d-flex flex-row my-3 justify-content-between align-items-center">
                  <LabelText>Tumnagelbild</LabelText>
                  <CustomInputFile
                    fileType="image"
                    setFile={setThumbnailFile}
                    file={thumbnailFile}
                    accept="image/png"
                  />
                </div>
                <div className="d-flex flex-row justify-content-center">
                  <ButtonSubmit type="submit" value="Skapa övning" />
                </div>
              </form>
          </div>
        </Modal.Body>
      </Modal>

      <ExerciseBankContainer className="container mb-5 py-3 px-0">
        <div className="col-md-12 d-flex align-items-center justify-content-between mb-3">
          <ExerciseBankTitle>Övningsbank</ExerciseBankTitle>
          <div className="d-flex flex-row align-items-center">
            <TextAddExercise>Lägg till övning</TextAddExercise>

            <FontAwesomeIcon
              icon={faPlusCircle}
              color="#930000"
              style={{
                cursor: "pointer",
              }}
              onClick={handleShow}
            />
          </div>
        </div>
        <div
          className="container col-md-12 mx-0"
          style={{
            overflowY: "auto",
          }}
        >
          {exerciseList
            ? exerciseList.map((exercise, index) => {
                return <ExerciseItem key={index} exerciseData={exercise} />;
              })
            : null}
        </div>
      </ExerciseBankContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedApplication: state.exerciseList.selectedExerciseApplication,
    accessToken: state.administrator.accessToken,
    exerciseList: state.exerciseList.exercises,
    isLoading: state.exerciseList.isLoading,
  };
};

const mapDispatchToProps = (dispatch, getState) => {
  return {
    fecthExerciseList: (selectedApplication) =>
      dispatch(exerciseListAction.fetchExerciseList(selectedApplication)),
    addExercise: (exerciseData, handleClose, refetch) =>
      dispatch(
        exerciseListAction.addExercise(exerciseData, handleClose, refetch)
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExercisesListContainer);
