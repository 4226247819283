import React, { useEffect } from "react";
import YouTube from 'react-youtube';

import "./about.css";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <main className="about-container">
        <div className="about-desc-container">
          <h2 className="about-title">
            Ylva berättar
          </h2>
          <p className="about-desc">
            Ylva utvecklas av oss gynekologer tillsammans med sjukgymnaster, barnmorskor och uroterapeuter som brinner för kvinnornas hälsa och välbefinnande. Vi  utvecklar individanpassad träning för att förebygga och behandla tillstånd som drabbar många kvinnor. Smärta i bäckenet, bäckenbotten och ryggen, urinläckage samt klimakteriebesvär går att lindra med hjälp av träning. Vi har i  Ymca samlat all kunskap så att kvinnor kan få hjälp, var de än befinner sig i livet. Vi vill nå många kvinnor och tycker därför att vår träning ska vara gratis.
          </p>
        </div>
        <div className="about-video-container">
          <iframe
            className="video-frame"
            src="https://www.youtube.com/embed/Aez4LgK1sFY"
            frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>
          </iframe>
        </div>
      </main>
    </>
  );
};
{/* <YouTube
  videoId='NUYvbT6vTPs'
  id="vidYT"
  className="video-frame"
  opts={{
    playerVars: {
      autoplay: 0,
    },
  }}
/> */}

export default AboutUs;
