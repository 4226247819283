import React from "react"


import SwishLogo from "../../assets/images/swish_payment_logo_v2.png"
import DescImg from "../../assets/images/undraw_a_whole_year.png"

import "./section4.css"

export default function Section4 (props) {


    return (
        <>
            <div className="section4-container">
                <div className="swish-number-container">
                    <img className="swish-logo" src={SwishLogo} alt="" />
                </div>
                <div className="swish-desc-container">
                    <img className="desc-img" src={DescImg} alt="" />
                    <span className="swish-title" >
                        Swish Donation.
                    </span>
                    <span className="swish-desc" >
                        Vår träning är gratis så att alla kvinnor kan använda den. Vill du bidra till utveckling och förbättringen, swisha ett frivilligt belopp 🙏
                    </span>
                </div>
            </div>
        </>
    )
}