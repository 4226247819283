import React, { useState } from "react";

import LogoutButton from "../../components/LogoutButton";

import axios from "../../utils/axios";
import Swal from "sweetalert2";

const Account = (props) => {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== passwordConfirmation) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "lösenord och lösenord för bekräftelse matchade inte!",
      });
    } else {
      axios({
        method: "POST",
        url: "/auth/change-password",
        data: {
          newPassword: password,
          newPasswordConfirmation: passwordConfirmation,
        },
        headers: {
          authorization: localStorage.getItem("accessToken"),
        },
      })
        .then(({ data }) => {
          localStorage.setItem("accessToken", data.accessToken);
        })
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "lösenord uppdaterat!",
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });
        });
    }
  };

  return (
    <div className="col-md-12">
      <div className="d-flex flex-row justify-content-between">
        <h1
          style={{
            fontWeight: "bold",
            fontSize: 30,
            lineHeight: "43px",
          }}
        >
          Kontoinställningar
        </h1>
        <LogoutButton />
      </div>
      <div
        className="col-md-5 mt-5 px-3 py-3"
        style={{
          boxShadow: "0px 0px 3.64313px rgba(0, 0, 0, 0.25)",
        }}
      >
        <h2
          className="mb-4"
          style={{
            fontStyle: "normal",
            fontSize: 18,
            fontWeight: 600,
            lineHeight: "26px",
          }}
        >
          Lösenord
        </h2>
        <span>************</span>
        <form className="mt-4" onSubmit={handleSubmit}>
          <div className="form-group mb-3">
            <input
              type="password"
              className="form-control"
              placeholder="Nytt lösenord"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{
                backgroundColor: "#ECECEC",
                color: "#000",
                "::placeholder": {
                  color: "#000",
                },
              }}
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder="Upprepa lösenord"
              required
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              style={{
                backgroundColor: "#ECECEC",
              }}
            />
          </div>
          <div className="d-flex flex-row-reverse">
            <button
              type="submit"
              className="btn"
              style={{
                backgroundColor: "#930000",
                color: "#fff",
                borderRadius: 17,
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: 15,
                lineHeight: "22px",
                width: 145,
              }}
            >
              ändra
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Account;
