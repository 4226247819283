import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

import ExerciseDetail from "./ExerciseDetail";

const ExerciseItem = (props) => {
  const { exerciseData } = props;

  const [detailOpen, setDetailOpen] = useState(false);

  return (
    <>
      <div
        className="d-flex flex-column px-0 mb-1 py-1"
        style={{
          borderBottom: "1px solid #000000",
        }}
      >
        <div
          className="d-flex flex-row justify-content-between mb-1"
          style={{ cursor: "pointer" }}
          onClick={() => setDetailOpen(!detailOpen)}
        >
          <span
            style={{
              fontWeight: "normal",
              fontStyle: "normal",
              fontSize: 15,
              lineHeight: "22px",
            }}
          >
            {exerciseData.name}
          </span>
          <FontAwesomeIcon icon={detailOpen ? faCaretUp : faCaretDown} />
        </div>
        {detailOpen ? <ExerciseDetail exerciseData={exerciseData} setDetailOpen={setDetailOpen} /> : null}
      </div>
    </>
  );
};

export default ExerciseItem;
