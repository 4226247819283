import { combineReducers } from "redux";

import websiteReducer from "./websiteReducer";
import administratorReducer from "./administratorReducer";
import exerciseReducer from "./exerciseReducer";
import statisticReducer from "./statisticReducer";
import trainingReducer from "./trainingScheduleReducer"

export default combineReducers({
  website: websiteReducer,
  administrator: administratorReducer,
  exerciseList: exerciseReducer,
  statistic: statisticReducer,
  training: trainingReducer,
});
