import React, {useEffect, useRef} from "react";
import { connect } from "react-redux";
import {
  XAxis,
  YAxis,
  VerticalBarSeries,
  FlexibleWidthXYPlot,
} from "react-vis";

import statisticAction from '../../store/actions/statisticAction'

const CompletedTraining = (props) => {

  const {
    levels,
    chosenLevel,
    completedTraining,
    fetchCompletedTraining,
    changeLevel,
  } = props
  
  const selectLevel = useRef(null)

  useEffect(() => {
    if(chosenLevel) {
      fetchCompletedTraining(chosenLevel)
    }
  }, [chosenLevel])

  useEffect(() => {
    selectLevel.current.selectedIndex = 0
  }, [levels])

  return (
    <div className="col-md-6 pl-0">
      <div
        className="d-flex flex-column"
        style={{
          boxShadow: "0px 0px 3.64313px rgba(0, 0, 0, 0.25)",
        }}
      >
        <div className="d-flex flex-row justify-content-between align-items-center py-3 px-3 mb-3">
          <span
            style={{
              fontWeight: 600,
              fontSize: 18,
            }}
          >
            Genomförd Träning
          </span>
          <select
            ref={selectLevel}
            onChange={(e) => changeLevel(e.target.value)}
            className="form-control col-md-5"
            style={{
              color: "#000",
            }}
          >
            {!levels ? 'loading..' : 
              levels.map((x, key) => {
                return [
                <option key={key} value={x.id}>{`Nivå ${x.number}`}</option>
                ]
              })
            }
          </select>
        </div>
        <div className="container">
          <FlexibleWidthXYPlot
            xType="ordinal"
            height={400}
            color="#930000"
            yDomain={[0, 100]}
          >
            <XAxis />
            <YAxis tickFormat={tick => tick+'%'} />
            <VerticalBarSeries data={completedTraining} animation />
          </FlexibleWidthXYPlot>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    levels: state.statistic.levels,
    chosenLevel: state.statistic.completedTrainingChosenLevel,
    completedTraining: state.statistic.completedTraining
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCompletedTraining: (levelId) => {
      dispatch(statisticAction.fetchCompletedTraining(levelId))
    },
    changeLevel: (levelId) => {
      dispatch(statisticAction.changeCompletedTrainingLevel(levelId))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompletedTraining);
