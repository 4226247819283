const initialState = {
  isLoading: true,
  isError: false,
  defaultErrorMessage: "internal server error",
  errorMessage: null,
};

const websiteReducer = (state = initialState, action) => {
  switch (action.type) {
    case "START_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "STOP_LOADING":
      return {
        ...state,
        isLoading: false,
      };
    case "SET_ERROR":
      return {
        ...state,
        isError: true,
        errorMessage: action.errorMessage,
      };

    default:
      return state;
  }
};

export default websiteReducer;
