import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom"

import "./roundBG.css"


const RoundBG = (props) => {

    let bg1 = useRef(null)
    let location = useLocation()

    useEffect(()=>{
        if (location.pathname === "/tos" || location.pathname === "/privacy") {
            bg1.current.style.display = "none"
        } else {
            bg1.current.style.display = "block"
        }
        if (location.pathname === "/login" || location.pathname === "/administrator") {
            bg1.current.style.display = "none"
        } else {
            bg1.current.style.display = "block"
        }
    },[location])

    return (
        <div className="round-bg-container">
            <div className="round-bg bg1" ref={bg1}></div>
        </div>
    )
}

export default RoundBG