const initialState = {
  selectedExerciseApplication: 'YLVA',
  exercises: null,
  isLoading: false,
};

const exerciseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SELECTED_EXERCISE_APPLICATION":
      console.log('selected exercise app :', action.selectedExerciseApplication)
      return {
        ...state,
        selectedExerciseApplication: action.selectedExerciseApplication,
      };
    case "SET_EXERCISE_LIST":
      return {
        ...state,
        exercises: action.exercises,
      };
    case "START_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "FINISH_LOADING":
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default exerciseReducer;
