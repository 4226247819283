import React from "react";

const TopCornerRight = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        backgroundColor: "#930000",
        width: 888,
        height: 888,
        zIndex: -999,
        right: -340,
        top: -700,
        borderRadius: "50%",
      }}
    ></div>
  );
};

export default TopCornerRight;
