import React, { useEffect } from "react";
import TOSImage from "../../assets/images/undraw_tos.png";

const Cookies = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main className="container">
        <div className="row">
          <div className="col-md-7">
            <h1>Cookies</h1>
            <article>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cum nec
                ut imperdiet semper. Scelerisque lectus tristique vulputate
                aliquet. Felis volutpat vestibulum, sit pharetra laoreet
                tristique platea. Elementum vitae metus, mi tortor vestibulum.
                Cursus a id elementum eleifend odio id. Tincidunt tristique
                purus amet enim id. Tempor arcu adipiscing sed aliquet. Diam,
                ornare eu non amet feugiat malesuada et consequat suscipit. Odio
                tristique a urna dui. Sed nulla ipsum sed nibh auctor risus
                amet. Libero phasellus ipsum cursus id nunc mus nec imperdiet
                dui. Risus nisi, id porta tristique odio eu sed. Quam risus
                viverra vulputate ut quis nisl. Placerat aenean semper sit in
                massa. Ornare turpis dignissim non porttitor enim. Tellus
                hendrerit id in in ut lacinia ac ut. Ultrices nec vitae eget
                arcu porta sodales sed scelerisque. Vestibulum, in sit dolor
                pulvinar id ipsum, diam. Quam in id pellentesque sit.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cum nec
                ut imperdiet semper. Scelerisque lectus tristique vulputate
                aliquet. Felis volutpat vestibulum, sit pharetra laoreet
                tristique platea. Elementum vitae metus, mi tortor vestibulum.
                Cursus a id elementum eleifend odio id. Tincidunt tristique
                purus amet enim id. Tempor arcu adipiscing sed aliquet. Diam,
                ornare eu non amet feugiat malesuada et consequat suscipit. Odio
                tristique a urna dui. Sed nulla ipsum sed nibh auctor risus
                amet. Libero phasellus ipsum cursus id nunc mus nec imperdiet
                dui. Risus nisi, id porta tristique odio eu sed. Quam risus
                viverra vulputate ut quis nisl. Placerat aenean semper sit in
                massa. Ornare turpis dignissim non porttitor enim. Tellus
                hendrerit id in in ut lacinia ac ut. Ultrices nec vitae eget
                arcu porta sodales sed scelerisque. Vestibulum, in sit dolor
                pulvinar id ipsum, diam. Quam in id pellentesque sit.
              </p>
            </article>
          </div>
          <div className="col-md-4">
            <div className="d-flex flex-column justify-content-center big-image-sidebar">
              <img className="img-fluid" src={TOSImage} alt="Term Of Service" />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Cookies;
