const initialState = {
    selectedApplication: 'YLVA',
    totalUser: 0,
    avgAge: 0,
    hasChildren: 0,
    votePerSession: [],
    completedTraining: [],
    answersToQuestions: [],
    developmentSymptoms: [],
    levels: [],
    pieLevels: [],
    votesChosenLevel: null,
    completedTrainingChosenLevel: null,
    answersToQuestionsChosenLevel: null,
    answersToQuestionsChosenQuestion: null,
    symptomsChosenQuestion: null,
    symptomsChosenPain: null,
};

const statisticReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SELECTED_APPLICATION':
            return {
                ...state,
                selectedApplication: action.selectedApplication
            }
        case 'SET_TOTAL_USER':
            return {
                ...state,
                totalUser: action.totalUser
            }
        case 'SET_AVG_AGE':
            return {
                ...state,
                avgAge: action.avgAge
            }
        case 'SET_HAS_CHILDREN':
            return {
                ...state,
                hasChildren: action.hasChildren
            }
        case 'SET_VOTE_PER_SESSION':
            return {
                ...state,
                votePerSession: action.votePerSession
            }
        case 'SET_COMPLETED_TRAINING':
            return {
                ...state,
                completedTraining: action.completedTraining
            }
        case 'SET_ANSWERS_TO_QUESTIONS':
            return {
                ...state,
                answersToQuestions: action.answersToQuestions
            }
        case 'SET_DEVELOPMENT_SYMPTOMS':
            return {
                ...state,
                developmentSymptoms: action.developmentSymptoms
            }
        case 'SET_LEVELS':
            return {
                ...state,
                levels: action.levels,
                votesChosenLevel: action.votesChosenLevel,
                completedTrainingChosenLevel: action.completedTrainingChosenLevel,
                answersToQuestionsChosenQuestion: 1,
                symptomsChosenQuestion: 1,
                symptomsChosenPain: 1,
            }
        case 'SET_LEVELS_PIE':
            return {
                ...state,
                pieLevels: action.pieLevels,
                answersToQuestionsChosenLevel: action.answersToQuestionsChosenLevel,
            }
        case 'SET_VOTES_CHOSEN_LEVEL':
            return {
                ...state,
                votesChosenLevel: action.votesChosenLevel,
            }
        case 'SET_COMPLETED_TRAINING_CHOSEN_LEVEL':
            return {
                ...state,
                completedTrainingChosenLevel: action.completedTrainingChosenLevel,
            }
        case 'SET_ANSWERS_TO_QUESTIONS_CHOSEN_LEVEL':
            return {
                ...state,
                answersToQuestionsChosenLevel: action.answersToQuestionsChosenLevel,
            }
        case 'SET_ANSWERS_TO_QUESTIONS_CHOSEN_QUESTION':
            return {
                ...state,
                answersToQuestionsChosenQuestion: action.answersToQuestionsChosenQuestion,
            }
        case 'SET_SYMPTOMS_CHOSEN_QUESTION':
            return {
                ...state,
                symptomsChosenQuestion: action.symptomsChosenQuestion,
            }
        case 'SET_SYMPTOMS_CHOSEN_PAIN':
            return {
                ...state,
                symptomsChosenPain: action.symptomsChosenPain,
            }
        default:
            return state;
    }
}

export default statisticReducer