import request from './helper/request'
import validateToken from './helper/validateToken'


const selectApp = (app) => (dispatch, getState) => {
    dispatch({
        type: 'SET_SELECTED_APPLICATION',
        selectedApplication: app
    })
}

const fetchTotalUser = () => (dispatch, getState) => {

    const appType = getState().statistic.selectedApplication

    if (!localStorage.getItem('accessToken')) {
        validateToken(dispatch)
    } else {
        const method = 'GET'
        const url = 'users/total'
        const headers = {}
        request(appType, method, url, headers, null, dispatch, (data)=> {
            dispatch({
                type: 'SET_TOTAL_USER',
                totalUser: data.result
            })
        })
    }       
}

const fetchHasChildren = (isHaveChild) => (dispatch, getState) => {

    const appType = getState().statistic.selectedApplication

    if (!localStorage.getItem('accessToken')) {
        validateToken(dispatch)
    } else {
        const method = 'GET'
        const url = `users/have-children/${isHaveChild}`
        const headers = {}
        request(appType, method, url, headers, null, dispatch, (data)=> {
            dispatch({
                type: 'SET_HAS_CHILDREN',
                hasChildren: data.result.totalUser
            })
        })
    }       
}

const fetchAvgAge = () => (dispatch, getState) => {

    const appType = getState().statistic.selectedApplication

    if (!localStorage.getItem('accessToken')) {
        validateToken(dispatch)
    } else {
        const method = 'GET'
        const url = 'users/averageAge'
        const headers = {}
        request(appType, method, url, headers, null, dispatch, (data)=> {
            dispatch({
                type: 'SET_AVG_AGE',
                avgAge: data.result
            })
        })
    }       
}

const fetchVotesPerSession = (levelId) => (dispatch, getState) => {

    const appType = getState().statistic.selectedApplication

    if (!localStorage.getItem('accessToken')) {
        validateToken(dispatch)
    } else {
        const method = 'GET'
        const url = `levels/${levelId}/sessions/total-vote`
        const headers = {}
        request(appType, method, url, headers, null, dispatch, (data)=> {
            dispatch({
                type: 'SET_VOTE_PER_SESSION',
                votePerSession: data.result
            })
        })
    }       
}

const fetchCompletedTraining = (levelId) => (dispatch, getState) => {

    const appType = getState().statistic.selectedApplication

    if (!localStorage.getItem('accessToken')) {
        validateToken(dispatch)
    } else {
        const method = 'GET'
        const url = `levels/${levelId}/sessions/total-user-completed-training`
        const headers = {}
        request(appType, method, url, headers, null, dispatch, (data)=> {
            dispatch({
                type: 'SET_COMPLETED_TRAINING',
                completedTraining: data.result
            })
        })
    }       
}

const fetchAnswersToQuestions = (questionNumber ,level) => (dispatch, getState) => {

    const appType = getState().statistic.selectedApplication

    if (!localStorage.getItem('accessToken')) {
        validateToken(dispatch)
    } else {
        const method = 'GET'
        const url = `answers/total-user-per-level-pie-chart/${questionNumber}/${level}`
        const headers = {}
        request(appType, method, url, headers, null, dispatch, (data)=> {
            dispatch({
                type: 'SET_ANSWERS_TO_QUESTIONS',
                answersToQuestions: data.result
            })
        })
    }       
}

const fetchDevelopmentSymptoms = (questionNumber ,painNumber) => (dispatch, getState) => {

    const appType = getState().statistic.selectedApplication

    if (!localStorage.getItem('accessToken')) {
        validateToken(dispatch)
    } else {
        const method = 'GET'
        const url = `answers/total-user-per-level-line-chart/${questionNumber}/${painNumber}`
        const headers = {}
        request(appType, method, url, headers, null, dispatch, (data)=> {
            dispatch({
                type: 'SET_DEVELOPMENT_SYMPTOMS',
                developmentSymptoms: data.result
            })
        })
    }       
}

const fetchLevels = () => (dispatch, getState) => {

    console.log('fetching levels')

    const appType = getState().statistic.selectedApplication

    if (!localStorage.getItem('accessToken')) {
        validateToken(dispatch)
    } else {
        const query = `?limit=20`
        const method = 'GET'
        const url = `levels${query}`
        const headers = {}
        request(appType, method, url, headers, null, dispatch, (data)=> {
            dispatch({
                type: 'SET_LEVELS',
                levels: data.result.data,
                votesChosenLevel: data.result.data[0].id,
                completedTrainingChosenLevel: data.result.data[0].id,
                // answersToQuestionsChosenLevel: data.result.data[0].id,
            })
        })
    }       
}

const fetchLevelsForPieChart = () => (dispatch, getState) => {

    console.log('fetching levels for pie')

    const appType = getState().statistic.selectedApplication

    if (!localStorage.getItem('accessToken')) {
        validateToken(dispatch)
    } else {
        const query = `?limit=20`
        const method = 'GET'
        const url = `levels${query}`
        const headers = {}
        request(appType, method, url, headers, null, dispatch, (data)=> {
            data.result.data.unshift({number: 'S', id: null})
            dispatch({
                type: 'SET_LEVELS_PIE',
                pieLevels: data.result.data,
                answersToQuestionsChosenLevel: data.result.data[0].number,
            })
        })
    }       
}

const changeVotesLevel = (levelId) => (dispatch, getState) => {
    dispatch({
        type: 'SET_VOTES_CHOSEN_LEVEL',
        votesChosenLevel: levelId
    })
}

const changeCompletedTrainingLevel = (levelId) => (dispatch, getState) => {
    dispatch({
        type: 'SET_COMPLETED_TRAINING_CHOSEN_LEVEL',
        completedTrainingChosenLevel: levelId
    })
}

const changeAnswersToQuestionsLevel = (level) => (dispatch, getState) => {
    dispatch({
        type: 'SET_ANSWERS_TO_QUESTIONS_CHOSEN_LEVEL',
        answersToQuestionsChosenLevel: level
    })
}

const changeAnswersToQuestionsChosenQuestion = (questionNumber) => (dispatch, getState) => {
    dispatch({
        type: 'SET_ANSWERS_TO_QUESTIONS_CHOSEN_QUESTION',
        answersToQuestionsChosenQuestion: questionNumber
    })
}

const changeSymptomsQuestion = (questionNumber) => (dispatch, getState) => {
    dispatch({
        type: 'SET_SYMPTOMS_CHOSEN_QUESTION',
        symptomsChosenQuestion: questionNumber
    })
}

const changeSymptomsPain = (painNumber) => (dispatch, getState) => {
    dispatch({
        type: 'SET_SYMPTOMS_CHOSEN_PAIN',
        symptomsChosenPain: painNumber
    })
}

export default {
    selectApp,
    fetchTotalUser,
    fetchHasChildren,
    fetchAvgAge,
    fetchVotesPerSession,
    fetchCompletedTraining,
    fetchAnswersToQuestions,
    fetchDevelopmentSymptoms,
    fetchLevels,
    fetchLevelsForPieChart,
    changeVotesLevel,
    changeCompletedTrainingLevel,
    changeAnswersToQuestionsLevel,
    changeAnswersToQuestionsChosenQuestion,
    changeSymptomsQuestion,
    changeSymptomsPain,
}