import React from "react";

import AppStoreImage from "../../assets/images/app_store.png";
import GooglePlayImage from "../../assets/images/googlePlay.png";

import AppPreviewImg from "../../assets/images/movingPhone01.gif";

import "./section1.css"

export default function Section1(props) {
  return (
    <>
      <div className="section1-container">
        <div className="section1-description-container">
          <h2 className="section1-title">Träning för dig,<br/> på allvar!</h2>
          <p className="section1-desc">Individanpassad träning vid smärta i bäcken och underliv, inkontinens och klimakteriebesvär.</p>
          <div className="download-container-desktop">
            <img src={AppStoreImage} className="download-img" />
            <img src={GooglePlayImage} className="download-img" />
          </div>
        </div>
        <div className="mobile-app-container">
          <img src={AppPreviewImg} className="mobile-app-img" />
          <div className="download-container-mobile">
            <img src={AppStoreImage} className="download-img" />
            <img src={GooglePlayImage} className="download-img" />
          </div>
        </div>
      </div>
    </>
  );
}
