const initialState = {
  application: "YLVA",
  levels: null,
  sessions: null,
  chosenLevel: null,
};

const trainingScheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_APPLICATION":
      return {
        ...state,
        application: action.application,
      };
    case "SET_TRAINING_LEVELS":
      console.log(`training reducer L16, fetched levels :`, action.levels)
      return {
        ...state,
        levels: action.levels,
        chosenLevel: action.chosenLevel
      };
    case "SET_SESSIONS":
      console.log('sessions result :', action.sessions)
      return {
        ...state,
        sessions: action.sessions,
      };
    case "SET_CHOSEN_LEVEL":
      return {
        ...state,
        chosenLevel: action.chosenLevel,
      };

    default:
      return state;
  }
};

export default trainingScheduleReducer;
