import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom"

import "./roundBG.css"

const FootRoundBG = (props) => {

    let location = useLocation()
    let roundBg = useRef(null)

    useEffect(() => {
        if (location.pathname === "/tos" || location.pathname === "/privacy") {
            roundBg.current.style.display = "none"
        } else {
            roundBg.current.style.display = "block"
        }
        if (location.pathname === "/about") {
            roundBg.current.style.right = "auto"
            roundBg.current.style.left = "-15vw"
        } else {
            roundBg.current.style.right = "0"
            roundBg.current.style.left = "auto"
        }
        if (location.pathname === "/login" || location.pathname === "/administrator") {
            roundBg.current.style.right = "auto"
            roundBg.current.style.left = "-15vw"
        } else {
            roundBg.current.style.right = "0"
            roundBg.current.style.left = "auto"
        }

    }, [location])

    return (
        <div className="round-bg bg4" ref={roundBg}></div>
    )
}

export default FootRoundBG