import React, { useEffect, useState } from "react";

// import axios from "../../utils/axios"

import UndrawContactImage from "../../assets/images/undraw_contact.png";
import "./contact.css";

const ContactUs = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // const contactData = {
    //   name: name,
    //   email: email,
    //   message: message,
    // };
  };

  return (
    <>
      <main className="container" id="contact-us">
        <div className="row">
          <div className="col-md-6">
            <h1>Kontakta oss</h1>
            <div id="form-container">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Namn"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="E-post"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    rows={5}
                    placeholder="Meddelande"
                    required
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    required
                    id="formCheck"
                  />
                  <label className="form-check-label" htmlFor="formCheck">
                    Jag godkänner att dela med mig av mina kontaktuppgifter.
                  </label>
                </div>
                <div className="text-center my-5">
                  <input
                    type="submit"
                    className="btn btn-primary"
                    value="Skicka"
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={UndrawContactImage}
              alt="Term Of Service"
            />
          </div>
        </div>
      </main>
    </>
  );
};

export default ContactUs;
