import request from './helper/request'
import validateToken from './helper/validateToken'
import Swal from 'sweetalert2'

const fetchLevels = () => (dispatch, getState) => {

    const appType = getState().exerciseList.selectedExerciseApplication

    console.log('fetching training levels')
    if (!localStorage.getItem('accessToken')) {
        validateToken(dispatch)
    } else {
        const query = `?limit=20`
        const method = 'GET'
        const url = `levels${query}`
        const headers = {}
        request(appType, method, url, headers, null, dispatch, (data)=> {
            dispatch({
                type: 'SET_TRAINING_LEVELS',
                levels: data.result.data,
                chosenLevel: data.result.data[0].id,
            })
        })
    }       
}

const fetchSessions = (levelId) => (dispatch, getState) => {

    const appType = getState().exerciseList.selectedExerciseApplication

    if (!localStorage.getItem('accessToken')) {
        validateToken(dispatch)
    } else {
        const method = 'GET'
        const url = `levels/${levelId}/sessions`
        const headers = {}
        request(appType, method, url, headers, null, dispatch, (data)=> {
            dispatch({
                type: 'SET_SESSIONS',
                sessions: data.result.data,
            })
        })
    }       
}

const postSubsessions = (url, submitData, refetch, chosenLevel, handleClose) => (dispatch, getState) => {

    const appType = getState().exerciseList.selectedExerciseApplication

    if (!localStorage.getItem('accessToken')) {
        validateToken(dispatch)
    } else {
        const method = 'POST'
        const headers = {}
        request(appType, method, url, headers, submitData, dispatch, (data)=> {
            handleClose();
            refetch(chosenLevel)
            Swal.close()
            Swal.fire({
              icon: "success",
              title: "Träningsscheman tillagd!",
            });
        })
    }
}

const patchSubsessions = (url, submitData, refetch, chosenLevel, handleClose) => (dispatch, getState) => {

    const appType = getState().exerciseList.selectedExerciseApplication

    if (!localStorage.getItem('accessToken')) {
        validateToken(dispatch)
    } else {
        const method = 'PATCH'
        const headers = {}
        request(appType, method, url, headers, submitData, dispatch, (data)=> {
            handleClose();
            refetch(chosenLevel)
            Swal.close()
            Swal.fire({
              icon: "success",
              title: "Träningsscheman ändrats!",
            });
        })
    }
}

const deleteSubsessions = (url, refetch, chosenLevel, handleClose) => (dispatch, getState) => {

    const appType = getState().exerciseList.selectedExerciseApplication

    if (!localStorage.getItem('accessToken')) {
        validateToken(dispatch)
    } else {
        const method = 'DELETE'
        const headers = {}
        request(appType, method, url, headers, null, dispatch, (data)=> {
            handleClose();
            refetch(chosenLevel)
            Swal.close()
            Swal.fire({
              icon: "success",
              title: "Träningsscheman Tog Bort!",
            });
        })
    }
}

const changeChosenLevel = (levelId) => (dispatch, getState) => {
    if (!localStorage.getItem('accessToken')) {
        validateToken(dispatch)
    } else {
        dispatch({
            type: 'SET_CHOSEN_LEVEL',
            chosenLevel: levelId
        })
    }
}

export default {
    fetchLevels,
    fetchSessions,
    changeChosenLevel,
    postSubsessions,
    patchSubsessions,
    deleteSubsessions,
}