import React, { useState } from "react";
import styled from "styled-components";
import trainingActions from "../../store/actions/trainingActions";
import { Modal } from "react-bootstrap";
import axios from "../../utils/axios";
import Swal from "sweetalert2";
import { connect } from "react-redux";

const TitleModalDelete = styled.span`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18.2156px;
  line-height: 26px;
`;

const ModalTitle = styled(Modal.Title)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18.2156px;
  line-height: 26px;
`;

const ButtonSubmit = styled.input`
  background-color: #930000;
  border-radius: 17px;
  color: #fff;
  width: 200px;
  height: 35px;
  outline: none;
`;

const SubSessionItem = (props) => {
  const {
    chosenLevel,
    session,
    sessionNum,
    subsession,
    exerciseList,
    stack,
    refetch,
    patchSubsession,
    deleteSubsession,
  } = props

  //State Delete Modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleShowDeleteModal = () => setShowDeleteModal(true);

  //state Edit Modal
  const [showEditModal, setShowEditModal] = useState(false);
  const handleCloseEditModal = () => setShowEditModal(false);
  const handleShowEditModal = () => setShowEditModal(true);

  const actionRemove = (e) => {
    e.preventDefault();

    const url = `levels/${chosenLevel}/sessions/${session.id}/subsessions/${subsession.id}`
    deleteSubsession(url, refetch, chosenLevel, handleCloseDeleteModal)
  };

  const [exerciseID, setExerciseID] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const submitData = {
      exercise: exerciseID,
    };

    const url = `levels/${chosenLevel}/sessions/${session.id}/subsessions/${subsession.id}`
    patchSubsession(url, submitData, refetch, chosenLevel, handleCloseEditModal)
  };

  return (
    <>
      <div className="d-flex flex-row col-md-12 px-0 py-2">
        <div className="col-md-2 px-0">
          <span
            style={{
              fontWeight: "normal",
              fontStyle: "normal",
              fontSize: 15,
              lineHeight: "22px",
            }}
          >
            Session {`${sessionNum}.${stack}`}
          </span>
        </div>
        <div className="col-md-7">
          <span
            style={{
              fontWeight: "normal",
              fontStyle: "normal",
              fontSize: 15,
              lineHeight: "22px",
            }}
          >
            {subsession.exercise ? subsession.exercise.name : 'err'}
          </span>
        </div>
        <div className="col-md-3 d-flex flex-row justify-content-around">
          <button
            style={{
              backgroundColor: "#930000",
              color: "#fff",
              fontWeight: "bold",
              fontStyle: "normal",
              fontSize: 11.5,
              lineHeight: "16.5px",
              textAlign: "center",
              width: 88,
              height: 18,
              borderRadius: 17,
              outline:'none',
            }}
            onClick={handleShowEditModal}
          >
            Ändra
          </button>
          <button
            style={{
              backgroundColor: "#fff",
              color: "#930000",
              border: "1px solid #930000",
              fontWeight: "bold",
              fontStyle: "normal",
              fontSize: 11.5,
              lineHeight: "16.5px",
              textAlign: "center",
              width: 88,
              height: 18,
              borderRadius: 17,
              outline:'none',
            }}
            onClick={handleShowDeleteModal}
          >
            Ta bort
          </button>
        </div>
      </div>

      {/* Modal Delete */}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Body>
          <div className="container text-center py-5">
            <TitleModalDelete>
              Är du säker på att du vill ta bort övningen?
            </TitleModalDelete>
            <p>Session {`${sessionNum}.${stack}`}</p>
            <div className="container d-flex flex-row justify-content-around">
              <button type='button' onClick={actionRemove} style={{
                  backgroundColor: "#930000",
                  borderRadius: "17px",
                  color: "#fff",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "14.9794px",
                  width: "190px",
                  height: "35px",
                  outline: "none",
              }}>
                Ta bort
              </button>
              <button type='button' onClick={handleCloseDeleteModal} style={{
                backgroundColor: "#fff",
                borderRadius: "17px",
                color: "#930000",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "14.9794px",
                width: "190px",
                height: "35px",
                outline: "none",
              }}>
                Ha kvar
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal Edit */}
      <Modal show={showEditModal} onHide={handleCloseEditModal}>
        <Modal.Header closeButton>
          <ModalTitle>Lägg till övning i Sessionen</ModalTitle>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="">
                Session {`${sessionNum}.${stack}`}
                </label>
                <select
                  className="form-control"
                  defaultValue={exerciseID}
                  onChange={(e) => {
                    setExerciseID(e.target.value)
                  }}
                  required
                >
                  <option value="" disabled hidden>
                    Select
                  </option>
                  {exerciseList.map((exercise, i) => {
                    return (
                      <option key={i} value={exercise.id}>
                        {exercise.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="d-flex flex-row justify-content-center">
                <ButtonSubmit type="submit" value="Lägg till" />
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch, getState) => {
  return {
    refetch: (levelId) => {
      dispatch(trainingActions.fetchSessions(levelId))
    },
    patchSubsession: (url, submitData, refetch, chosenLevel, handleClose) => {
      dispatch(trainingActions.patchSubsessions(url, submitData, refetch, chosenLevel, handleClose))
    },
    deleteSubsession: (url, refetch, chosenLevel, handleClose) => {
      dispatch(trainingActions.deleteSubsessions(url, refetch, chosenLevel, handleClose))
    },
  };
};

export default connect(null, mapDispatchToProps) (SubSessionItem);
