import websiteAction from '../websiteAction'
import Swal from 'sweetalert2'

const validateToken = async (dispatch) => {
    dispatch(websiteAction.setError("You are not authorized for this section."))
    Swal.fire({
        icon: 'Error',
        title: "Oops...",
        text: "You are not authorized for this section."
    })
}

export default validateToken