import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";

const ThumbSession = (props) => {

  return (
    <div
      className="col-md-12 d-flex flex-row justify-content-between px-0 pb-1 mb-2"
      style={{
        borderBottom: "2px solid #930000",
      }}
    >
      <div className="col-md-6 px-0">
      <span>{props.session}</span>
      </div>
      <div className="col-md-6 px-0 d-flex flex-row justify-content-around">
        <div style={{
            flex: 1,
          }}
        >
          {`${Math.round(props.voteDown)}%`}
          <FontAwesomeIcon
            icon={faThumbsDown}
            color="#930000"
            style={{ marginLeft: 10 }}
          />
        </div>

        <span
          style={{
            color: "#930000",
            textAlign: 'center',
            flex: 1,
          }}
        >
          |
        </span>
        <div
          style={{ 
            flex: 1,
          }}
        >
        {`${Math.round(props.voteUp)}%`}
          <FontAwesomeIcon
            icon={faThumbsUp}
            color="#930000"
            style={{ marginLeft: 10 }}
          />
        </div>
      </div>
    </div>
  );
};

export default ThumbSession;
