import React, { useEffect } from "react";
import styled from "styled-components";

import LogoutButton from "../../components/LogoutButton";
import SelectApplication from "./SelectApplication";

import ExerciseListContainer from "./ExercisesListContainer";
import TrainingScheduleContainer from "./TrainingScheduleContainer";

import trainingActions from "../../store/actions/trainingActions";
import exerciseListAction from '../../store/actions/exerciseListAction'
import { connect } from "react-redux";

const Title = styled.h1`
  font-weight: bold;
  font-size: 30px;
  line-height: 43px;
`;

const Exercise = (props) => {

  const {
    selectApp,
    fetchLevels,
  } = props

  useEffect( () => {
    selectApp('YLVA')
    fetchLevels()
  }, [])

  return (
    <>
      <div className="col-md-12">
        <div className="d-flex flex-row justify-content-between mb-4">
          <Title>Träningsscheman</Title>
          <LogoutButton />
        </div>

        <SelectApplication/>
        <ExerciseListContainer/>
        <TrainingScheduleContainer/>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectApp: (app) => {
      dispatch(exerciseListAction.setSelectedApplication(app))
    },
    fetchLevels: () => {
      dispatch(trainingActions.fetchLevels())
    },
  };
};

export default connect(null, mapDispatchToProps) (Exercise);
