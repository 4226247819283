import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";

import statisticAction from '../../store/actions/statisticAction'
import ThumbSession from "./ThumbSession";

const VotePerSession = (props) => {

  const {
    levels,
    chosenLevel,
    votes,
    fetchVotes,
    changeLevel,
    selectedApp,
  } = props

  const selectLevel = useRef(null)

  useEffect(() => {
    if(chosenLevel) {
      fetchVotes(chosenLevel)
    }
  }, [chosenLevel])

  useEffect(() => {
    selectLevel.current.selectedIndex = 0
  }, [levels])


  return (
    <div className="col-md-6 pl-0">
      <div
        className="d-flex flex-column py-3 px-3"
        style={{
          boxShadow: "0px 0px 3.64313px rgba(0, 0, 0, 0.25)",
        }}
      >
        <div className="d-flex flex-row justify-content-between align-items-center mb-4">
          <span
            style={{
              fontWeight: 600,
              fontSize: 18,
            }}
          >
            Röster per session
          </span>
          <select
            className="form-control col-md-5"
            ref={selectLevel}
            onChange={(e) => {
              changeLevel(e.target.value)
            }}
            style={{
              color: "#000",
            }}
          >
            {!levels ? 'loading..' : 
              levels.map((x, key) => {
                return [
                <option key={key} value={x.id}>{`Nivå ${x.number}`}</option>
                ]
              })
            }
          </select>
        </div>
        <div className="d-flex flex-column pb-2">
          {!votes ? console.log('loading') : 
            votes.map((x, key) => {
              return [
              <ThumbSession key={key} session={x.session} voteUp={x.voteUp} voteDown={x.voteDown} />
              ]
            })
          }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    levels: state.statistic.levels,
    chosenLevel: state.statistic.votesChosenLevel,
    votes: state.statistic.votePerSession,
    selectedApp: state.statistic.selectedApplication,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchVotes: (levelId) => {
      dispatch(statisticAction.fetchVotesPerSession(levelId))
    },
    changeLevel: (levelId) => {
      dispatch(statisticAction.changeVotesLevel(levelId))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VotePerSession);
