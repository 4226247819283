import React, { useRef, useEffect } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { NavHashLink as Link } from "react-router-hash-link"
import NavRoundBG from "../RoundBG/NavRoundBG"
import Logo from "../../assets/images/logo-white.png"
import Toggler from "../../assets/images/icons/red-toggler-icon.svg"
import CloseIcon from "../../assets/images/icons/white-cross.svg"

import "./navigation.css";

const Navigation = (props) => {
  let location = useLocation();
  const webHeader = useRef(null);
  const logoImg = useRef(null);
  const menu1 = useRef(null);
  const menu2 = useRef(null);
  const menu3 = useRef(null);
  const menu4 = useRef(null);
  const sideMenu1 = useRef(null);
  const sideMenu2 = useRef(null);
  const sideMenu3 = useRef(null);
  const sideMenu4 = useRef(null);
  const sidebarContainer = useRef(null)
  const sidebar = useRef(null)

  //scroll to top
  useEffect(() => {
    window.scrollTo(0,0)
    // location.pathname = "/"
    menu1.current.style.color = "#930000"
    menu1.current.style["font-weight"] = "bold"
    sideMenu1.current.style.color = "#fff"
    sideMenu1.current.style["font-weight"] = "bold"
  }, [])

  useEffect(() => {
    webHeader.current.style["padding-top"] = "0vw"
  }, [location])

  const openSidebar = () => {
    sidebarContainer.current.style.display ="block"
    setTimeout(() => {
      sidebar.current.style.left = "0vw"
    },100)
  }
  
  const closeSidebar = (e) => {
    e.stopPropagation()
    setTimeout(() => {
      sidebar.current.style.left = "-100vw"
      setTimeout(() => {
        sidebarContainer.current.style.display ="none"
      },300)
    },100)
  }

  return (
    <>
      <NavRoundBG />
      <header className="nav-wrapper" ref={webHeader}>
        <div className="left-container">
            <img className="toggler" src={Toggler} onTouchStart={openSidebar} />
            <div className="menu-container">
              <Link to="/" activeStyle={{color: '#930000', fontWeight: "bold"}} className="menu" ref={menu1}>
                Hemma hos Ylva
              </Link>
              <Link to="/#steg" activeStyle={{color: '#930000', fontWeight: "bold"}} className="menu" ref={menu2} >
                Om Ylva
              </Link>
              <Link to="/#kom-med-i-ylva" activeStyle={{color: '#930000', fontWeight: "bold"}} className="menu" ref={menu3}>
                Kom med i Ylva
              </Link>
              <Link to="/about" activeStyle={{color: '#930000', fontWeight: "bold"}} className="menu" ref={menu4}>
                Vi är Ylva
              </Link>
            </div>
        </div>
        <div className="right-container">
          <Link to="/" className="logo-container">
              <img className="logo" src={Logo} ref={logoImg} />
          </Link>
        </div>
        <div className="sidebar-container" ref={sidebarContainer} onTouchStart={(e) => closeSidebar(e)}>
          <div className="sidebar" ref={sidebar}>
            <div className="sidebar-head">
              <div className="sidebar-logo-container">
                <img className="sidebar-logo" src={Logo}/>
              </div>
              <div className="close-button-container">
                <img className="close-button" src={CloseIcon} onTouchStart={(e) => closeSidebar(e)}/>
              </div>
            </div>
            <div className="sidebar-menu-container">
              <Link to="/" activeStyle={{color: '#fff', fontWeight: "bold"}} className="side-menu" ref={sideMenu1} /* onTouchStart={(e) => navHandler(e, null, 'mobile')}*/>
                <p>Hemma hos Ylva</p>
              </Link>
              <Link to="/#steg" activeStyle={{color: '#fff', fontWeight: "bold"}} className="side-menu" ref={sideMenu2} /* onTouchStart={(e) => navHandler(e, "steg", 'mobile')}*/>
                <p>Om Ylva</p>
              </Link>
              <Link to="/#kom-med-i-ylva" activeStyle={{color: '#fff', fontWeight: "bold"}} className="side-menu" ref={sideMenu3} /* onTouchStart={(e) => navHandler(e, "kom-med-i-ylva", 'mobile')}*/>
                <p>Kom med i Ylva</p>
              </Link>
              <Link to="/about" activeStyle={{color: '#fff', fontWeight: "bold"}} className="side-menu" ref={sideMenu4} /* onTouchStart={(e) => navHandler(e, null, 'mobile')}*/>
                <p>Vi är Ylva</p>
              </Link>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default withRouter(Navigation);
