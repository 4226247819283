import React from "react";
import { Route, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Home from "../containers/home";
import AboutUs from "../containers/about_us";
import TermOfUse from "../containers/term_of_use";
import PrivacyPolicy from "../containers/privacy_policy";
import Cookies from "../containers/cookies";
import Contact from "../containers/contact";
import Login from "../containers/login";

import LoadingScreen from "../containers/LoadingScreen";

import Navigation from "../components/navigation/navigation";
import Footer from "../components/footer/footer";

function LandingPageRouter(props) {
  return (
    <>
      <Navigation />
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/about">
        <AboutUs />
      </Route>
      <Route exact path="/tos">
        <TermOfUse />
      </Route>
      <Route exact path="/privacy">
        <PrivacyPolicy />
      </Route>
      <Route exact path="/cookies">
        <Cookies />
      </Route>
      <Route exact path="/contact">
        <Contact />
      </Route>
      <Route exact path="/login">
        {props.isLoading ? (
          <LoadingScreen />
        ) : !props.isLogin ? (
          <Login />
        ) : (
          <Redirect to="/administrator" />
        )}
      </Route>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isLogin: state.administrator.isLogin,
    isLoading: state.website.isLoading,
  };
};

export default connect(mapStateToProps)(withRouter(LandingPageRouter));
