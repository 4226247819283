import React, { useEffect } from "react";

import StatisticHeader from "./StatisticHeader";
import SelectApplication from "./SelectApplication";
import HasChildrenOption from "./HasChildrenOption";
import UserData from "./UserData";
import AvgAge from "./AvgAge";
import VotePerSession from "./VotePerSession";
import CompletedTraining from "./CompletedTraining";
import AnswerToQuestion from "./AnswerToQuestion";
import DevelopmentSymptom from "./DevelopmentSymptom";

import statisticAction from '../../store/actions/statisticAction'
import { connect } from "react-redux";

const Statistic = (props) => {

const { fetchLevels, selectedApp, selectApp, pieChartLevels } = props

  useEffect(() => {
    selectApp('YLVA')
    fetchLevels()
    pieChartLevels()
  }, [])

  return (
    <div className="col-md-12 px-0">
      <StatisticHeader />

      <div className="d-flex flex-row justify-content-start mb-3">
        <SelectApplication fetchLevels={fetchLevels}  />
        {
          selectedApp == "YLVA_GRAVID" ?
          <HasChildrenOption />
          :
          null
        }
      </div>
      <div className="d-flex flex-row justify-content-around mb-3">
        <UserData />
        <AvgAge />
      </div>
      <div className="d-flex flex-row justify-content-around mb-3">
        <VotePerSession />
        <CompletedTraining />
      </div>
      <div className="d-flex flex-row justify-content-around mb-3">
        <AnswerToQuestion />
        <DevelopmentSymptom />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedApp: state.statistic.selectedApplication
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchLevels: () => {
      dispatch(statisticAction.fetchLevels())
    },
    selectApp: (app) => {
      dispatch(statisticAction.selectApp(app))
    },
    pieChartLevels: () => {
      dispatch(statisticAction.fetchLevelsForPieChart())
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Statistic);
