import React from "react";

import SubSessionItem from "./SubSessionItem";
import SubSessionItemEnd from "./SubSessionItemEnd";

const ScheduleDetail = (props) => {
  
  const {
    exerciseList,
    session,
    chosenLevel,
    sessionNum
   } = props

   console.log('session from schedule detail :', session)

  return (
    <div className="d-flex flex-column py-3">
      {session
        ? session.subsessions.map((subsession, i) => {
            return (
              <SubSessionItem
                key={i}
                exerciseList={exerciseList}
                subsession={subsession}
                session={session}
                stack={i + 1}
                sessionNum={sessionNum}
                chosenLevel={chosenLevel}
                />
                );
              })
              : null}
      {
        session ?
        <SubSessionItemEnd
          session={session}
          sessionNum={sessionNum}
          stack={session.subsessions.length + 1}
          chosenLevel={chosenLevel}
        />
        :
        null
      }
    </div>
  );
};

export default ScheduleDetail;
