import React from "react";

import LogoutButton from "../../components/LogoutButton";

const StatisticHeader = (props) => {
  return (
    <div className="d-flex flex-row justify-content-between mb-5">
      <h1
        style={{
          fontWeight: "bold",
          fontSize: 30,
          lineHeight: "43px",
        }}
      >
        Användarstatistik
      </h1>
      <LogoutButton />
    </div>
  );
};

export default StatisticHeader;
