import React, {useState} from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import CustomInputFile from "./CustomInputFile";
import exerciseListAction from "../../store/actions/exerciseListAction";
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { useEffect } from "react";
import Swal from "sweetalert2";

const SpanLabel = styled.span`
  font-weight: normal;
  font-style: normal;
  font-size: 15px;
  line-height: 22px;
`;

const ModalTitle = styled(Modal.Title)`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #000;
`;

const LoadingSpinner = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LabelText = styled.span`
  font-size: 15;
  font-weight: normal;
  font-style: normal;
  color: #000;
`;

const ModalFormSelect = styled.select`
  color: #000;
  font-size: 18px;
`;

const TextAreaInput = styled.textarea`
  width: 60%;
`;

const ExerciseDetail = (props) => {
  const {
    exerciseData,
    isLoading,
    fetchExerciseList,
    deleteExercise,
    editExercise,
    setDetailOpen,
  } = props;
  
  //edit states
  const [name, setName] = useState(exerciseData.name);
  const [activeTimeSec, setActiveTimeSec] = useState(0);
  const [activeTimeMin, setActiveTimeMin] = useState(0);
  const [restTimeSec, setRestTimeSec] = useState(0);
  const [restTimeMin, setRestTimeMin] = useState(0);
  const [repetition, setRepetition] = useState(exerciseData.repetition);
  const [description, setDescription] = useState(exerciseData.description);
  const [audioFile, setAudioFile] = useState(exerciseData.audioName);
  const [audioFileName, setAudioFileName] = useState(exerciseData.audioName);
  const [videoFile, setVideoFile] = useState(exerciseData.videoName);
  const [videoFileName, setVideoFileName] = useState(exerciseData.videoName);
  const [thumbnailFile, setThumbnailFile] = useState(exerciseData.thumbnailName || '');
  const [thumbnailFileName, setThumbnailFileName] = useState(exerciseData.thumbnailName || '');

  const convertSec = () => {
    if (exerciseData.activeTime > 59) {
      setActiveTimeMin((exerciseData.activeTime - (exerciseData.activeTime % 60)) / 60)
      setActiveTimeSec(exerciseData.activeTime % 60)
    } else {
      setActiveTimeSec(exerciseData.activeTime)
    }
    if (exerciseData.restTime > 59) {
      setRestTimeMin((exerciseData.restTime - (exerciseData.restTime % 60)) / 60)
      setRestTimeSec(exerciseData.restTime % 60)
    } else {
      setRestTimeSec(exerciseData.restTime)
    }
  }

  useEffect( ()=> {
    if (exerciseData) {
      convertSec()
    }
  }, [])

  //State Delete Modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleShowDeleteModal = () => setShowDeleteModal(true);

  //state Edit Modal
  const [showEditModal, setShowEditModal] = useState(false);
  const handleCloseEdit = () => {
    setShowEditModal(false);
    clearModal()
  };
  const handleShowEdit = () => setShowEditModal(true);

  const submitHandle = (e) => {
    e.preventDefault();
    console.log(activeTimeSec, activeTimeMin)
    if (activeTimeSec == 0 && activeTimeMin == 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Active time must be above 0 second.',
      })
    } else if (restTimeMin == 0 && restTimeSec == 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Rest time must be above 0 second.',
      })
    } else {
      const submitData = {
        name: name,
        activeTimeSec: activeTimeSec,
        activeTimeMin: activeTimeMin,
        restTimeSec: restTimeSec,
        restTimeMin: restTimeMin,
        repetition: repetition,
        description: description,
        audio: audioFile,
        audioName: audioFileName,
        video: videoFile,
        videoName: videoFileName,
        thumbnail: thumbnailFile,
        thumbnailName: thumbnailFileName,
      };
      editExercise(submitData, exerciseData.id, fetchExerciseList);
      setDetailOpen(false)
      handleCloseEdit()
    }
  };

  const clearModal = () => {
    convertSec()
    setName(exerciseData.name);
    setRepetition(exerciseData.repetition);
    setDescription(exerciseData.description);
    setAudioFile(exerciseData.audioName);
    setVideoFile(exerciseData.videoName);
    setThumbnailFile(exerciseData.thumbnailName || '');
    setAudioFileName(exerciseData.audioName);
    setVideoFileName(exerciseData.videoName);
    setThumbnailFileName(exerciseData.thumbnailName || '');
  };

  const handleSubmitDelete = (e) => {
    deleteExercise(exerciseData.id, handleCloseDeleteModal)
    setTimeout(()=>{
      fetchExerciseList()
    },5000)
  }

  return (
    <div className="d-flex flex-column pt-4 pb-3">
      <div className="d-flex flex-row col-md-6 px-0 mb-2">
        <div className="col-md-5 px-0">
          <SpanLabel>Namn</SpanLabel>
        </div>
        <div className="col-md-7">
          <SpanLabel>{exerciseData.name}</SpanLabel>
        </div>
      </div>
      <div className="d-flex flex-row col-md-6 px-0 mb-2">
        <div className="col-md-5 px-0">
          <SpanLabel>Aktiv tid</SpanLabel>
        </div>
        <div className="col-md-7">
          <SpanLabel>{exerciseData.activeTime > 59 ?
          `${activeTimeMin} Min ${activeTimeSec} Sec`
          : `${activeTimeSec} Sec`}</SpanLabel>
        </div>
      </div>
      <div className="d-flex flex-row col-md-6 px-0 mb-2">
        <div className="col-md-5 px-0">
          <SpanLabel>Vilotid</SpanLabel>
        </div>
        <div className="col-md-7">
          <SpanLabel>{exerciseData.restTime > 59 ?
          `${restTimeMin} Min ${restTimeSec} Sec`
          : `${restTimeSec} Sec`}</SpanLabel>
        </div>
      </div>
      <div className="d-flex flex-row col-md-6 px-0 mb-2">
        <div className="col-md-5 px-0">
          <SpanLabel>Repititioner</SpanLabel>
        </div>
        <div className="col-md-7">
          <SpanLabel>{exerciseData.repetition}</SpanLabel>
        </div>
      </div>
      <div className="d-flex flex-row col-md-6 px-0 mb-2">
        <div className="col-md-5 px-0">
          <SpanLabel>Description</SpanLabel>
        </div>
        <div className="col-md-7">
          <SpanLabel>{exerciseData.description}</SpanLabel>
        </div>
      </div>
      <div className="d-flex flex-row col-md-6 px-0 mb-2">
        <div className="col-md-5 px-0">
          <SpanLabel>Ljudfil</SpanLabel>
        </div>
        <div className="col-md-7">
          {exerciseData.audioName ? (
            <span>
              {exerciseData.audioName}
            </span>
          ) : (
            <SpanLabel>No Audio File</SpanLabel>
          )}
        </div>
      </div>
      <div className="d-flex flex-row col-md-6 px-0 mb-2">
        <div className="col-md-5 px-0">
          <SpanLabel>Videoklipp</SpanLabel>
        </div>
        <div className="col-md-7">
          {exerciseData.videoName ? (
            <span>
              {exerciseData.videoName}
            </span>
          ) : (
            <SpanLabel>No Video File</SpanLabel>
          )}
        </div>
      </div>
      <div className="d-flex flex-row col-md-6 px-0 mb-2">
        <div className="col-md-5 px-0">
          <SpanLabel>Tumnagelbild</SpanLabel>
        </div>
        <div className="col-md-7">
          {exerciseData.thumbnailName ? (
            <span>
              {exerciseData.thumbnailName}
            </span>
          ) : (
            <SpanLabel>No Thumbnail File</SpanLabel>
          )}
        </div>
      </div>
      <div className="d-flex flex-row justify-content-end">
        <button
          onClick={handleShowEdit}
          style={{
            margin: '0 1vw',
            backgroundColor: "#930000",
            color: "#fff",
            fontWeight: "bold",
            fontStyle: "normal",
            fontSize: 11.5,
            lineHeight: "16.5px",
            textAlign: "center",
            width: 88,
            height: 18,
            borderRadius: 17,
            outline: 'none'
          }}
        >
          Ändra
        </button>
        <button
          onClick={handleShowDeleteModal}
          style={{
            margin: '0 1vw',
            backgroundColor: "#fff",
            color: "#930000",
            border: "1px solid #930000",
            fontWeight: "bold",
            fontStyle: "normal",
            fontSize: 11.5,
            lineHeight: "16.5px",
            textAlign: "center",
            width: 88,
            height: 18,
            borderRadius: 17,
            outline: 'none'
          }}
        >
          Ta bort
        </button>
      </div>
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Body>
          <div className="container text-center py-5">
            <span>
              Är du säker på att du vill ta bort övningen?
            </span>
            <p>Sub Session</p>
            <div className="container d-flex flex-row justify-content-around">
              <button
                onClick={handleSubmitDelete}
                style={{
                  margin: '0 1vw',
                  backgroundColor: "#930000",
                  color: "#fff",
                  fontWeight: "bold",
                  fontStyle: "normal",
                  fontSize: "1vw",
                  lineHeight: "16.5px",
                  textAlign: "center",
                  width: '15vw',
                  height: '2.4vw',
                  borderRadius: 17,
                  outline: 'none',
                }}  
              >
                Ta bort
              </button>
              <button
                onClick={handleCloseDeleteModal}
                style={{
                  margin: '0 1vw',
                  backgroundColor: "#fff",
                  color: "#930000",
                  border: "1px solid #930000",
                  fontWeight: "bold",
                  fontStyle: "normal",
                  fontSize: "1vw",
                  lineHeight: "16.5px",
                  textAlign: "center",
                  width: '15vw',
                  height: '2.4vw',
                  borderRadius: 17,
                  outline: 'none',
                }}
              >
                Ha kvar
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showEditModal} centered onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <ModalTitle>Ändra övning</ModalTitle>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12">
            {isLoading ? (
              <LoadingSpinner>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
                <span>Uploading Data...</span>
              </LoadingSpinner>
            ) : (
              <form onSubmit={submitHandle}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Namn"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
                <div className="d-flex flex-row my-3 justify-content-between align-items-center">
                  <LabelText>Aktiv tid</LabelText>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}>
                    <ModalFormSelect
                      className="form-control"
                      defaultValue={activeTimeMin}
                      onChange={(e) => setActiveTimeMin(e.target.value)}
                      style={{
                        width: '7.6vw',
                      }}
                    >
                      <option value={0} >
                        0 Min
                      </option>
                      {Array.from(Array(59), (e, i) => {
                        return (
                          <option key={i+1} value={i+1}>
                            {i+1} Min
                          </option>
                        );
                      })}
                    </ModalFormSelect>
                    <ModalFormSelect
                      className="form-control"
                      defaultValue={activeTimeSec}
                      onChange={(e) => setActiveTimeSec(e.target.value)}
                      style={{
                        width: '7.6vw',
                        marginLeft: '1vw'
                      }}
                    >
                      <option value={0} >
                        0 Sec
                      </option>
                      {Array.from(Array(59), (e, i) => {
                        return (
                          <option key={i+1} value={i+1}>
                            {i+1} Sec
                          </option>
                        );
                      })}
                    </ModalFormSelect>
                  </div>
                </div>
                <div className="d-flex flex-row my-3 justify-content-between align-items-center">
                  <LabelText>Vilotid</LabelText>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}>
                    <ModalFormSelect
                      className="form-control"
                      defaultValue={restTimeMin}
                      onChange={(e) => setRestTimeMin(e.target.value)}
                      style={{
                        width: '7.6vw',
                      }}
                    >
                      <option value={0} >
                        0 Min
                      </option>
                      {Array.from(Array(59), (e, i) => {
                        return (
                          <option key={i+1} value={i+1}>
                            {i+1} Min
                          </option>
                        );
                      })}
                    </ModalFormSelect>
                    <ModalFormSelect
                      className="form-control"
                      defaultValue={restTimeSec}
                      onChange={(e) => setRestTimeSec(e.target.value)}
                      style={{
                        width: '7.6vw',
                        marginLeft: '1vw'
                      }}
                    >
                      <option value={0} >
                        0 Sec
                      </option>
                      {Array.from(Array(59), (e, i) => {
                        return (
                          <option key={i+1} value={i+1}>
                            {i+1} Sec
                          </option>
                        );
                      })}
                    </ModalFormSelect>
                  </div>
                </div>
                <div className="d-flex flex-row my-3 justify-content-between align-items-center">
                  <LabelText>Repititioner</LabelText>
                  <ModalFormSelect
                    className="form-control col-md-3"
                    defaultValue={repetition}
                    onChange={(e) => setRepetition(e.target.value)}
                  >
                    <option value={0} disabled hidden>
                      Antal
                    </option>
                    {Array.from(Array(90), (e, i) => {
                      return (
                        <option key={i+1} value={i+1}>
                          {i+1}
                        </option>
                      );
                    })}
                  </ModalFormSelect>
                </div>
                <div className="d-flex flex-row my-3 justify-content-between align-items-center">
                  <LabelText>Beskrivning</LabelText>
                  <TextAreaInput
                    className="form-control"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></TextAreaInput>
                </div>
                <div className="d-flex flex-row my-3 justify-content-between align-items-center">
                  <LabelText>Ljudfil</LabelText>
                  <CustomInputFile
                    fileType="audio"
                    fileName={audioFileName}
                    setFile={setAudioFile}
                    file={audioFile}
                    accept="audio/mpeg"
                  />
                </div>
                <div className="d-flex flex-row my-3 justify-content-between align-items-center">
                  <LabelText>Videoklipp</LabelText>
                  <CustomInputFile
                    fileType="video"
                    fileName={videoFileName}
                    setFile={setVideoFile}
                    file={videoFile}
                    accept="video/mp4"
                  />
                </div>
                <div className="d-flex flex-row my-3 justify-content-between align-items-center">
                  <LabelText>Tumnagelbild</LabelText>
                  <CustomInputFile
                    fileType="image"
                    fileName={thumbnailFileName}
                    setFile={setThumbnailFile}
                    file={thumbnailFile}
                    accept="image/png"
                  />
                </div>
                <div className="d-flex flex-row justify-content-center">
                  <button
                    style={{
                      color: "#fff",
                      backgroundColor: "#930000",
                      borderRadius: "17px",
                      textAlign: "center",
                      height: "35px",
                      width: "200px",
                      fontSize: "15px",
                      fontWeight: "bold",
                      outline: 'none',
                    }}
                  >Ändra övning</button>
                </div>
              </form>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.exerciseList.isLoading,
  };
};

const mapDispatchToProps = (dispatch, getState) => {
  return {
    fetchExerciseList: async () =>
      dispatch(exerciseListAction.fetchExerciseList()),
    deleteExercise: (exerciseId, handleClose) =>
      dispatch(exerciseListAction.deleteExercise(exerciseId, handleClose)),
    editExercise: (submitData, handleClose, refetch) => {
      dispatch(exerciseListAction.editExercise(submitData, handleClose, refetch))
    }
  };
};

export default connect(mapStateToProps,mapDispatchToProps) (ExerciseDetail);
