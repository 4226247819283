import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom"

import "./roundBG.css"


const RoundBG = (props) => {

    return (
        <div className="round-bg-container container3">
            <div className="round-bg bg3"></div>
        </div>
    )
}

export default RoundBG