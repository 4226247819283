import React from "react";
import styled from "styled-components";

const LabelButton = styled.label`
  color: #930000;
  background-color: transparent;
  border-radius: 17px;
  text-align: center;
  height: 35px;
  width: 125px;
  font-size: 15px;
  font-weight: bold;
  border: 1px solid #930000;
`;

const LabelText = styled.label``;

const InputFile = styled.input`
  display: none;
`;

const CustomInputFile = (props) => {
  const { fileType, file, setFile, fileName, accept } = props;

  return (
    <div className="d-flex flex-row">
      {file ? (
        <>
          <LabelText htmlFor={fileType}>
            {file.name || fileName} (
            <span
              style={{
                color: '#930000'
              }}
            >
            Ändra
            </span>
            )
          </LabelText>
        </>
      ) : (
        <LabelButton htmlFor={fileType} className="btn">
          Ladda upp
        </LabelButton>
      )}
      <InputFile
        id={fileType}
        type="file"
        onChange={(e) => {
          if (e.target.files[0]) {
            setFile(e.target.files[0]);
          }
        }}
        accept={accept}
      />
    </div>
  );
};

export default CustomInputFile;
