import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import trainingActions from "../../store/actions/trainingActions";
import ScheduleItem from "./ScheduleItems";

const MainContainer = styled.div`
  box-shadow: 0px 0px 3.64313px rgba(0, 0, 0, 0.25);
`;

const Title = styled.span`
  font-weight: 600;
  font-size: 18.2px;
  line-height: "26px";
`;

const SelectLevel = styled.select`
  color: #000;
  font-style: normal;
  font-weight: 500;
  font-size: 18;
  line-height: 26px;
`;

const TrainingScheduleContainer = (props) => {
  const {
    selectedApplication,
    levels,
    fetchLevels,
    sessions,
    fetchSessions,
    chosenLevel,
    changeChosenLevel,
    exerciseList
  } = props;

  const selectLevel = useRef(null)

  // useEffect(() => {
  //   fetchLevels()
  // }, [selectedApplication]);

  useEffect(() => {
    if (chosenLevel) {
      fetchSessions(chosenLevel)
    }
  }, [chosenLevel]);

  useEffect(()=> {
    if (levels) {
      selectLevel.current.selectedIndex = 0
    }
  }, [levels])

  return (
    <MainContainer className="container mb-5 py-3 px-0">
      <div className="col-md-12 d-flex align-items-center justify-content-between mb-3">
        <Title>Träningsscheman</Title>
        <SelectLevel
          ref={selectLevel}
          className="form-control col-md-2"
          onChange={(e) => changeChosenLevel(e.target.value)}
        >
          {!levels ? "loading.." :
            levels.map((x, key) => {
              return [
              <option key={key} value={x.id}>{`Nivå ${x.number}`}</option>
              ]
            })
          }
        </SelectLevel>
      </div>
      <div
        className="container col-md-12 mx-0"
        style={{
          overflowY: "auto",
        }}
      >
        {!sessions ? "loading.." :
          Array.from(Array(10), (e, i) => {
            return (
              <ScheduleItem
                chosenLevel={chosenLevel}
                session={sessions[i]}
                sessionId={sessions[i].id}
                count={i}
                exerciseList={exerciseList}
                key={i}
              />
            );
          })
        }
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    levels: state.training.levels,
    sessions: state.training.sessions,
    chosenLevel: state.training.chosenLevel,
    exerciseList: state.exerciseList.exercises,
    selectedApplication: state.exerciseList.selectedExerciseApplication,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchLevels: () => {
      dispatch(trainingActions.fetchLevels())
    },
    fetchSessions: (levelId) => {
      dispatch(trainingActions.fetchSessions(levelId))
    },
    changeChosenLevel: (levelId) => {
      dispatch(trainingActions.changeChosenLevel(levelId))
    },
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(TrainingScheduleContainer);
