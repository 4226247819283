/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";
// import websiteAction from "../store/actions/websiteAction";
import administratorAction from "../store/actions/administratorAction";

import LandingPageRouter from "./LandingPageRouter";
import AdministratorRouter from "./AdministratorRouter";

function MainRouter(props) {
  const checkLogin = props.checkLogin;

  useEffect(() => {
    checkLogin();
  }, []);

  return (
    <Router>
      <Switch>
        <Route
          path="/administrator"
          render={() => {
            return localStorage.getItem('accessToken') ? (
              <AdministratorRouter />
            ) : (
              <Redirect to="/login" />
            );
          }}
        />
        <LandingPageRouter />
      </Switch>
    </Router>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.website.isLoading,
    isLogin: state.administrator.isLogin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // startLoading: () => dispatch(websiteAction.startLoading()),
    // stopLoading: () => dispatch(websiteAction.stopLoading()),
    checkLogin: () => dispatch(administratorAction.checkLoginStatus()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainRouter);
