import axios from "../../utils/axios";
import Swal from "sweetalert2";
import request from './helper/request'

import websiteAction from "./websiteAction";

const checkLoginStatus = () => (dispatch, getState) => {
  dispatch(websiteAction.startLoading());
  if (!localStorage.getItem("accessToken")) {
    dispatch({
      type: "LOGOUT",
    });
    dispatch(websiteAction.stopLoading());
  }
};

const login = (email, password) => (dispatch, getState) => {

  const loginData = {
    email: email,
    password: password,
  }

  request('YLVA', 'POST', 'auth/login', {}, loginData, dispatch, (data) => {
    localStorage.setItem("accessToken", data.result.accessToken);
    dispatch({
      type: "LOGIN",
      accessToken: data.result.accessToken,
    });
  })
};

export default {
  checkLoginStatus,
  login,
};
