import React, { useEffect } from "react";
import TOSImage from "../../assets/images/undraw_tos.png";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main className="container">
        <div className="row">
          <div className="col-md-16 col-sm">
            <h1>Integritetspolicy</h1>
            <article>
              <p>Integritetspolicy för Ylva och YlvaGravid</p>

              <p>Vi värnar om din personliga integritet och strävar efter att erbjuda en säker och trygg användning av våra applikationer (Ylva samt YlvaGravid) och hemsidor (Ylva.app samt Ylvagravid.se) (nedan kallade applikationer, hemsidor, tjänster).  Integritetspolicyn gäller mellan dig som användare och Ylva/ YlvaGravid som drivs av HealthOnline Sweden AB 559104-9282, (vi, oss, vår, våra). För kontaktuppgifter se slutet av denna text.</p>

              <p>Denna integritetspolicy förklarar hur vi samlar in, behandlar, lagrar och använder din personliga information, nedan kallat personuppgifter. Den beskriver också dina rättigheter och hur du kan använda dem. Genom att använda någon av våra applikationer och/ eller hemsidor godkänner du att din personliga information behandlas i enlighet med denna integritetspolicy  och användarvillkor. Integritetspolicyn är ett komplement till våra användarvillkor. I det fall det råder motstridighet mellan Integritetspolicyn och användarvillkoren skal integritetspolicyn äga företräde. </p>

              <p>Om dataskyddsförordningen (GDPR)</p>

              <p>För de uppgifter du som användare lämnar och som är att betrakta som personuppgifter följer vi relevant personuppgiftslagstiftning. Framförallt avses då Dataskyddsförordningen och lag 2018:218 med kompletterande bestämmelser från Eus dataskyddsförordning. </p>

              <p>Vad är personuppgifter?</p>

              <p>Personuppgifter är information om en identifierad eller identifierbar fysisk person. En identifierbar fysisk person är en person som kan identifieras direkt eller indirekt, via ett namn, ett personnummer, platsdata, en IP-adress eller en eller flera faktorer som är specifika för fysiska, fysiologiska, genetiska, mentala, ekonomiska, kulturella eller sociala identiteten hos den fysiska personen.</p>

              <p>Vilka personuppgifter samlar vi in?</p>

              <p>Den personliga informationen du tillhandahåller via våra hemsidor och i våra appar används av oss för att tillhandahålla våra tjänster. Genom att lämna informationen samtycker du till att vi behandlar dina uppgifter. För att återkalla detta samtycke kan du själv radera uppgifterna i applikationen eller kontakta oss, se slutet av denna text för kontaktuppgifter. </p>
              <p>
                Grundläggande information och kontouppgifter:
                Namn (det namn du uppger vid registrering)
                Ålder
                Användartyp (gravid, har fött barn, har besvär)
                E-postadress (den e-postadress du väljer vi registrering)
                Information om din Graviditet och/ eller förlossning (t.ex första dagen i din senaste menscykel och/ eller beräknat förlossningsdatum), tidigare graviditeter)
              </p>
              <p>
                Uppgifter om din aktivitet och ditt hälsotillstånd. Dessa uppgifter är nödvändiga för att vi skall kunna erbjuda dig dina personliga individanpassade träningsprogram
                Vikt
                Träningsvana
                Smärta
              </p>

              <p>Vi samlar in uppgifter om din enhet och om hur du som besökare använder våra hemsidor och/ eller applikationer. Den här informationen behövs för att du ska kunna följa din utveckling och för att vi ska kunna erbjuda dig ditt individanpassade program baserat på din aktivitet. Denna information används också för att utveckla våra funktioner och innehåll och lagras och behandlas med stöd av en intresseavvägning. Data som samlas in är teknisk data, IP-adress, enhetsidentifierare, information om nätverks- och datorprestanda, platsinformation, typ av webbläsare, information om skärmupplösning, språk och information om identifiering och operativsystem.</p>

              <p>Hur används dina personuppgifter av oss?</p>
              <p>
                Den information du själv lämnar till oss eller som vi samlar in kan användas för att 
                För att tillgängliggöra och tillhandahålla våra tjänster i enlighet med användarvillkoren
                För att administrera och behandla ditt konto
                För att göra våra tjänster individanpassade och förbättra din upplevelse av dem.
                För att skicka notiser och meddelanden till dig via e-post och/ eller på annat sätt inklusive marknadsföring och information om våra och våra framtida samarbetspartners produkter och tjänster.
                För att förbättra och utveckla våra tjänster och produkter samt för att analysera ditt användande av dem.
                För att säkerställa de tekniska funktionerna i våra tjänster och för att förhindra användning av applikationen i strid med användarvillkoren. 
                För att uppfylla användarvillkoren inklusive att skydda våra rättigheter, egendom och säkerhet och om nödvändigt också tredje parts rättigheter, egendom och säkerhet. 
              </p>

              <p>Om du har aktiverat cookies i din webbläsare kan den informationen användas för att ge en personlig användarupplevelse.</p>
              <p>
                Mottagare av personuppgifterna
                Vi använder bara din personliga information i den mån det är nödvändigt för de ändamål som anges i denna integritetspolicy.
              </p>
              <p>
                Rättslig grund
                Vi behandlar dina personuppgifter baserat på uppfyllande av avtal, berättigat intresse och samtycke:
              </p>
              <p>
                Samtycke
                Genom att du registrerar ett konto i någon av våra applikationer Ylva/YlvaGravid accepterar du våra användarvillkor och integritetspolicy och samtycker till att dina personuppgifter får användas för angivna ändamål samt marknadsförings- och forskningssyfte. 
              </p>
              <p>
                Fullgörande av användarvillkoren
                Vi behandlar dina personuppgifter för att uppfylla våra förpliktelser gentemot dig som användare enligt användarvillkoren.
                Behandlingen är nödvändig för att fullgöra vårt avtal enligt denna policy eller vidta åtgärder på begäran av den registrerade innan ett sådant avtal ingåtts.
              </p>

              <p>Observera att du närsomhelst kan återkalla ditt samtycke genom att radera ditt konto i applikationen eller genom att kontakta oss, för kontaktuppgifter se slutet av detta dokument. Återkallandet av ditt samtycke påverkar inte legaliteten av behandlingen baserat på samtycket innan det återkallades och vi kan komma att fortsätta behandla dina personuppgifter baserat på andra rättsliga grunder. </p>

              <p>Utlämning av personuppgifter till tredje part</p>

              <p>Vi lämnar inte ut personuppgifter eller annan personlig information eller kombinerad personlig och demografisk information till extern part med undantag för följande situationer:</p>
              <p>
                Tjänsteleverantörer
                Detta är utomstående företag som anlitats av oss för att bedriva vår verksamhet och tillhandahålla våra tjänster. Tjänsteleverantörerna har bara tillåtelse att använda dina personuppgifter å våra vägnar för de särskilda uppgifter som de har att utföra efter våra anvisningar och agerar som personuppgiftsbiträden åt oss. Tjänsteleverantörer är skyldiga att hålla dina personuppgifter konfidentiellt och säkert.
              </p>
              <p>
                Samtliga tjänsteleverantörer är baserade i Sverige.
                (Alternativt vissa av våra tjänsteleverantörer är baserade utanför EU/ESS i länder som inte bedöms ha en adekvat skyddsnivå för personuppgifter. Vi har vidtagit rekommenderade skyddsåtgärder för personuppgifts överföringarna. Du kan kontakta oss om du vill få mer information angående dessa åtgärder.
              </p>

              <p>Vi förbehåller oss rätten att tillhandahålla information och innehåll för akademi och forskning som främjar kunskapsuppbyggnad och innovation inom graviditet, förlossning och kvinnohälsa.</p>

              <p>Leverantörer och tillhandahållare av analystjänster som möjliggör för oss att analysera hur våra tjänster används och genomföra enkätundersökningar (med ditt samtycke)</p>

              <p>Annonsörer (med ditt samtycke) för att kunna rikta annonser som passar din information och ditt beteende. </p>

              <p>Länkar till andra webbsidor. Om du klickar på en extern länk eller applikation lämnar du våra tjänster och från detta tillfälle är inget vi ansvariga för hur dessa webbplatser/applikationer hanterar dina personuppgifter. Vi rekommenderar att du alltid läser igenom integritetspolicyn för hur dessa platser hanterar dina personuppgifter i förhand.</p>
              <p>
                Juridiska orsaker
                Vi har rätt att lämna ut uppgifter om det finns en lagstadgad skyldighet för oss att göra det såsom om det begärs av ett offentligt organ eller annan tillsynsmyndighet eller om vi i god tro anser att ett sådant utlämnande är nödvändigt för att:
                Följa legala krav eller rätta oss efter ett domstolsbeslut
                Skydda rättigheterna eller äganderätten till Ylva/ YlvaGravid eller HealthOnline Sweden AB
                Förhindra ett brott eller skydda nationell säkerhet
                Skydda användarens personliga säkerhet eller allmänheten
              </p>

              <p>Om HealthOnline Sweden AB eller något av våra tillgångar förvärvas eller slås samman med ett annat företag inklusive genom konkurs kan HealthOnline Sweden AB komma att dela med sig av dina personuppgifter till någon av våra efterföljare.</p>

              <p>I övrigt gäller att vi inte säljer, handlar med, eller på annat sätt överför, personligt identifierbar information till utomstående parter.</p>
 
              <p>Hur länge behåller vi din personliga information</p>

              <p>Vi lagrar endast dina personuppgifter så länge du har ett konto hos oss, så fort du raderar ditt konto eller kontaktar oss om att du vill få dina personuppgifter raderade tar vi bort dem.</p>

              <p>Hur kan du utöva dina rättigheter</p>

              <p>Enligt dataskyddsförordningen har du, under vissa förutsättningar, rätt att få åtkomst till, korrigera, exportera och radera dina uppgifter. Du har också rätt att invända mot och begränsa viss behandling av dina uppgifter. Det kan dock finnas legala skyldigheter som hindrar oss från att omedelbart radera dina data. För all kommunikation angående hanteringen av dina personuppgifter kontakta vårt personuppgiftsombud, se kontaktuppgifter i slutet av detta dokument. </p>

              <p>Klagomål på vår behandling av dina personuppgifter</p>

              <p>Om du har klagomål kring vår behandling av dina personuppgifter har du rätta att inge klagomål till Datainspektionen - Svenska Integritetsskyddsmyndigheten eller annan behörig tillsynsmyndighet. </p>

              <p>Säkerhet</p>

              <p>Vi värnar om din personliga integritet och dina intressen och därav behandlas dina uppgifter med största försiktighet, med adekvat säkerhetsstandard och vi går vårt yttersta för att säkerställa en hög datasäkerhetsnivå. Vi kommer att vidta lämpliga åtgärder som krävs för att säkerställa att dina personuppgifter behandlas säkert och i enlighet med vår integritetspolicy. Överföring av dina personuppgifter till Ylva/YlvaGravid/ Healthonline Sweden AB sker dock på egen risk. I det fall en säkerhetsöverträdelse utanför vår kontroll inträffar kommer vi att agera så snart som möjligt för att åtgärda problemet. </p>


              <p>Ändringar av integritetspolicyn</p>

              <p>I händelse av väsentliga ändringar i integritetspolicyn kommer vi att meddela dig genom ett e-post meddelande till den adress du registrerat ditt konto på.</p>

              <p>Användning av cookies</p>
              <p>
                Cookies
                Vi använder cookies för att förbättra din användarupplevelse både i applikationerna och på hemsidan. Cookies är en liten textfil som sparas i din dator, ipad eller telefon när du besöker våra tjänster. Detta görs för att identifiera din plattform som en unik användare och för att öka användarvänligheten i tjänsten bland annat vid inloggning och för anpassning av innehållet efter dina önskemål och val. Cookies möjliggör också att vår tjänst kommer ihåg dina val i varje steg. Genom cookies kan vi också se hur många som besöker våra tjänster och föra statistik som kan användas för att förbättra tjänsten. Genom att använda någon av våra tjänster godkänner du detta.
              </p>
              <p>Du kan stänga av möjligheten för våra tjänster att spara cookies. Om du väljer att stänga av cookies kommer dock funktionaliteten bli sämre. Du kan också radera sparade cookiesfiler i din dator.</p>

              <p>Kontakta oss</p>

              <p>Om du har några frågor angående denna integritetspolicy, vår behandling av personuppgifter eller vill kontakta oss angående dina personuppgifter så hör du av dig till oss på info@ylvagravid.se; info@ylva.app eller info@healthonline.se</p>
            </article>
          </div>
          {/* <div className="col-md-4">
            <img className="img-fluid" src={TOSImage} alt="Term Of Service" />
          </div> */}
        </div>
      </main>
    </>
  );
};

export default PrivacyPolicy;
