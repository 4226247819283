const startLoading = () => (dispatch, getState) => {
  dispatch({
    type: "START_LOADING",
  });
};

const stopLoading = () => (dispatch, getState) => {
  dispatch({
    type: "STOP_LOADING",
  });
};

const setError = (errorMessage) => (dispatch, getState) => {
  dispatch({
    type: "SET_ERROR",
    errorMessage: errorMessage,
  });
};

export default {
  startLoading,
  stopLoading,
  setError,
};
