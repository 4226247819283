import React, {useEffect, useRef} from "react";
import { Link, useLocation } from "react-router-dom";
import LogoYlva from "../../assets/images/logo.svg";
import FacebookLogo from "../../assets/images/icons/facebook_circular_logo.svg";
import InstagramLogo from "../../assets/images/icons/instagram_logo.svg";
import RoundBG from "../RoundBG/FootRoundBG"

import "./footer.css";


const WebFooter = (prop) => {

  return (
    <>
      <div className="footer-container">
      <RoundBG />
        <footer>
          <div className="container-1">
            <div className="container-2">
              
              <div className="footer-link-container">
                <ul className="tos-container">
                  <li className="link">
                    <Link to="/tos">Användarvillkor</Link>
                  </li>
                  <li className="link">
                    <Link to="/privacy">Integritetspolicy</Link>
                  </li>
                </ul>
                <div className="socmed-container">
                  <a className="external-link" href="https://facebook.com" target="_blank" rel="noopener noreferrer"><img className="socmed-logo" src={FacebookLogo} alt="" /></a>
                  <a className="external-link" href="https://instagram.com/ylva_app" target="_blank" rel="noopener noreferrer"><img className="socmed-logo" src={InstagramLogo} alt="" /></a>
                </div>
              </div>
              <div className="logo-address-container">
                <img
                  className="logo-footer"
                  src={LogoYlva}
                  alt="Ylva Logo"
                />
                <address>
                  2020 Healthonline Sweden <br></br>
                  Utvecklad av Abersoft Technologies AB
                </address>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default WebFooter;
