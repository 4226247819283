import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { RadialChart } from "react-vis";

import statisticAction from '../../store/actions/statisticAction'

const AnswerToQuestion = (props) => {

  const {
    levels,
    pieLevels,
    answersToQuestions,
    chosenLevel,
    chosenQuestion,
    fetchAnswersToQuestions,
    changeQuestion,
    changeLevel,
    selectedApp,
  } = props

  const [questionText, setQuestionText] = useState('Har du smärta?')

  const selectLevel = useRef(null)
  const selectQuestion = useRef(null)

  useEffect( ()=> {
    if ( chosenLevel || chosenQuestion ) {
      fetchAnswersToQuestions(chosenQuestion, chosenLevel)
    }
  }, [chosenLevel, chosenQuestion, selectedApp])

  useEffect( () => {
    if (selectedApp === "YLVA") {
      setQuestionText('Har du smärta?')
    } else {
      setQuestionText('Mår du bra?')
    }
  }, [selectedApp])
  
  useEffect(() => {
    selectLevel.current.selectedIndex = 0
    selectQuestion.current.selectedIndex = 0
  }, [pieLevels])

  const changeQuestionText = (e) => {

    if (selectedApp === 'YLVA') {
      switch(e.target.value) {
        case "1" :
          setQuestionText('Har du smärta?')
          break;
    
        case "2" :
          setQuestionText('Har du urinläckage?')
          break;
    
        case "3" :
          setQuestionText('Har du ont i underlivet?')
          break ;
    
        case "4" :
          setQuestionText("Har du smärta vid samlag?")
          break ;
    
        default:
          setQuestionText('Har du smärta?')
      }
    } else {
      switch(e.target.value) {
        case "1" :
          setQuestionText('Mår du bra?')
          break;
    
        case "2" :
          setQuestionText('Sover du bra?')
          break;
    
        case "3" :
          setQuestionText('Har du ont när du går och står?')
          break ;
    
        case "4" :
          setQuestionText("Har du ont när du vilar?")
          break ;
    
        default:
          setQuestionText('Mår du bra?')
      }
    }
  }


  return (
    <div className="col-md-6 pl-0">
      <div
        className="d-flex flex-column"
        style={{
          boxShadow: "0px 0px 3.64313px rgba(0, 0, 0, 0.25)",
        }}
      >
        <div className="d-flex flex-row justify-content-between align-items-start py-3 px-3 mb-3">
          <span
            style={{
              fontWeight: 600,
              fontSize: 18,
            }}
          >
            Svar på frågor
          </span>
          <div className="d-flex flex-column col-md-5 px-0">
            <select
              ref={selectQuestion}
              onChange={(e) => {
                changeQuestion(e.target.value)
                changeQuestionText(e)
              }}
              className="form-control mb-2"
              style={{
                color: "#000",
              }}
            >
              <option value={1}>Fråga 1</option>
              <option value={2}>Fråga 2</option>
              <option value={3}>Fråga 3</option>
              <option value={4}>Fråga 4</option>
            </select>

            <select
              ref={selectLevel}
              onChange={(e) => changeLevel(e.target.value)}
              className="form-control"
              style={{
                color: "#000",
              }}
            >
              {!pieLevels ? 'loading..' :
                pieLevels.map((x, key) => {
                  return [
                  <option key={key} value={x.number}>{`Nivå ${x.number}`}</option>
                  ]
                })
              }
            </select>
          </div>
        </div>
        <div className="d-flex flex-row">
          <div className="d-flex flex-column justify-content-center align-items-center"
            style ={{
              flex: 1,
            }}
          >
            <span>{questionText}</span>
            <div>
              {answersToQuestions.length === 0 ? 'loading..' :
                <RadialChart
                  colorType="literal"
                  data={answersToQuestions}
                  width={250}
                  height={250}
                  animation
                />
              }
            </div>
          </div>

          <div
            className="d-flex flex-column justify-content-center"
            style={{
              flex: 1,
            }}
          >
            {
              answersToQuestions.map((x,key) => {
                return [
                  <div key={key} className="d-flex flex-row justify-content-between">
                    <div className="d-flex flex-row align-items-center">
                      <div
                        style={{
                          width: 10,
                          height: 10,
                          backgroundColor: x.color,
                          borderRadius: "50%",
                          marginRight: 10,
                        }}
                      ></div>
                      <span>
                        {x.label}
                      </span>
                    </div>
                    <span
                      style={{
                        paddingRight: 10
                      }}
                    >{x.angle.toFixed(2)}%</span>
                  </div>
                ]
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedApp: state.statistic.selectedApplication,
    levels: state.statistic.levels,
    answersToQuestions: state.statistic.answersToQuestions,
    chosenLevel: state.statistic.answersToQuestionsChosenLevel,
    chosenQuestion: state.statistic.answersToQuestionsChosenQuestion,
    pieLevels: state.statistic.pieLevels
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAnswersToQuestions: (questionNumber, level) => {
      if (level === 'Nivå S' || level === 'S') {
        level = 0
      }
      dispatch(statisticAction.fetchAnswersToQuestions(questionNumber, level))
    },
    changeLevel: (level) => {
      dispatch(statisticAction.changeAnswersToQuestionsLevel(level))
    },
    changeQuestion: (questionNumber) => {
      dispatch(statisticAction.changeAnswersToQuestionsChosenQuestion(questionNumber))
    },
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(AnswerToQuestion);
