import request from './helper/request'
import Swal from "sweetalert2";

const setSelectedApplication = (app) => (dispatch, getState) => {
  dispatch({
    type: "SET_SELECTED_EXERCISE_APPLICATION",
    selectedExerciseApplication: app,
  })
}

const fetchExerciseList = () => (dispatch, getState) => {

  const appType = getState().exerciseList.selectedExerciseApplication

  dispatch({
    type: "START_LOADING",
  });
  const method = 'GET'
  const url = 'exercises'
  const headers = {
    authorization: localStorage.getItem('accessToken'),
  }
  request(appType, method, url, headers, null, dispatch, (data)=> {
    dispatch({
      type: "SET_EXERCISE_LIST",
      exercises: data.result,
    });
    dispatch({
      type: "FINISH_LOADING",
    });
  })
};

const addExercise = (exerciseData, handleClose, refetch) => async (dispatch, getState) => {

  let {
    name,
    activeTimeSec,
    activeTimeMin,
    restTimeSec,
    restTimeMin,
    repetition,
    description,
    audio,
    video,
    thumbnail,
    application
  } = exerciseData

  let isEmpty = false

  if(!name) {
    isEmpty = true
  }
  if (!activeTimeSec && !activeTimeMin) {
    isEmpty = true
  }
  if (!restTimeSec && !restTimeMin) {
    isEmpty = true
  }
  if(!repetition) {
    isEmpty = true
  }
  if(!description) {
    isEmpty = true
  }
  if(!audio) {
    isEmpty = true
  }
  if(!video) {
    isEmpty = true
  }
  if(!thumbnail) {
    isEmpty = true
  }
  if(!application) {
    isEmpty = true
  }


  if (isEmpty) {
    Swal.fire({
      icon: "error",
      title: `ofullständiga uppgifter!`,
    });
  } else {

    const appType = getState().exerciseList.selectedExerciseApplication
  
    Swal.fire({
      title: `Uploading..`,
      showConfirmButton: false,
    });
    Swal.showLoading()
    handleClose()
    var formData = new FormData();
    formData.append("files", exerciseData.audio);
    formData.append("files", exerciseData.video);
    formData.append("files", exerciseData.thumbnail);
  
    const method = 'POST'
    const url = 'assets'
    const headers = {
      authorization: localStorage.getItem('accessToken'),
      'Content-Type': 'multipart/formdata',
    }
    request(appType, method, url, headers, formData, dispatch, (response)=> {
      const data = response.result
  
      data.forEach((x)=>{
        if (x.url.includes('mp4')) {
          exerciseData.video = x.path
          exerciseData.videoName = x.name
        } else if ( x.url.includes('mp3') ) {
          exerciseData.audio = x.path
          exerciseData.audioName = x.name
        } else if ( x.url.includes('png') ) {
          exerciseData.thumbnail = x.path
          exerciseData.thumbnailName = x.name
        }
      })
  
      const method1 = 'POST'
      const url1 = 'exercises/postExercise'
      const headers1 = {
        authorization: localStorage.getItem('accessToken'),
      }
      request(appType, method1, url1, headers1, exerciseData, dispatch, (data) => {
        refetch()
        Swal.close()
        Swal.fire({
          icon: "success",
          title: `övning tillagd!`,
        });
      })
    })
  }
};

const deleteExercise = (exerciseId, handleClose) => async (dispatch, getState) => {

  const appType = getState().exerciseList.selectedExerciseApplication

  dispatch({
    type: "START_LOADING",
  });

  const method = 'DELETE'
  const url = `exercises/deleteExercise/${exerciseId}`
  const headers = {
    authorization: localStorage.getItem('accessToken'),
  }
  request(appType, method, url, headers, null, dispatch, (data) => {
    dispatch({
      type: "FINISH_LOADING",
    });
    handleClose()
    Swal.fire({
      icon: "success",
      title: `övning tog bort!`,
    });
  })
}

const editExercise = (submitData, exerciseId, refetch,) => (dispatch, getState) => {

  let {
    name,
    activeTimeSec,
    activeTimeMin,
    restTimeSec,
    restTimeMin,
    repetition,
    description,
    audio,
    audioName,
    video,
    videoName,
    thumbnail,
    thumbnailName,
  } = submitData

  let isEmpty = false

  if(!name) {
    isEmpty = true
  }
  if (!activeTimeSec && !activeTimeMin) {
    isEmpty = true
  }
  if (!restTimeSec && !restTimeMin) {
    isEmpty = true
  }
  if(!repetition) {
    isEmpty = true
  }
  if(!description) {
    isEmpty = true
  }
  if(!audio) {
    isEmpty = true
  }
  if(!audioName) {
    isEmpty = true
  }
  if(!video) {
    isEmpty = true
  }
  if(!videoName) {
    isEmpty = true
  }
  if(!thumbnail) {
    isEmpty = true
  }
  if(!thumbnailName) {
    isEmpty = true
  }

  if (isEmpty) {
    Swal.fire({
      icon: "error",
      title: `ofullständiga uppgifter!`,
    });
  } else {

    const appType = getState().exerciseList.selectedExerciseApplication
  
    Swal.fire({
      title: `Uploading..`,
      showConfirmButton: false,
    });
    Swal.showLoading()
    if (typeof audio !== 'object' && typeof video !== 'object' && typeof thumbnail !== 'object') {
      submitData.audio = 'Audios/' + audioName
      submitData.video = 'Videos/' + videoName
      submitData.thumbnail = 'Thumbnails/' + thumbnailName
      // console.log(audio, video, thumbnail)
      // console.log(submitData.audio)

      const method = 'PUT'
      const url = `exercises/editExercise/${exerciseId}`
      const headers = {
        authorization: localStorage.getItem('accessToken'),
      }
      request(appType, method, url, headers, submitData, dispatch, (data) => {
        Swal.close()
        Swal.fire({
          icon: "success",
          title: `övning ändrats!`,
        });
        refetch()
      })
    } else {
      let formData = new FormData();
      if (typeof video == 'object') {
        formData.append("files", video);
      }
      if (typeof audio == 'object') {
        formData.append("files", audio);
      }
      if (typeof thumbnail == 'object') {
        formData.append("files", thumbnail);
      }

      
  
      const method = 'POST'
      const url = `assets`
      const headers = {
        authorization: localStorage.getItem('accessToken'),
        'Content-Type': 'multipart/formdata'
      }
      request(appType, method, url, headers, formData, dispatch, (data) => {
        
        data.result.forEach((x)=>{
          console.log(x, x.path)
          if (x.url.includes('mp4')) {
            submitData.video = x.path
            submitData.videoName = x.name
          } else if ( x.url.includes('mp3') ) {
            submitData.audio = x.path
            submitData.audioName = x.name
          } else if ( x.url.includes('png') ) {
            submitData.thumbnail = x.path
            submitData.thumbnailName = x.name
          }
        })

        if (!submitData.audio.includes('Audios/')) {
          submitData.audio = 'Audios/' + submitData.audioName
        }
        if (!submitData.video.includes('Videos/')) {
          submitData.video = 'Videos/' + submitData.videoName
        }
        if (!submitData.thumbnail.includes('Thumbnails/')) {
          submitData.thumbnail = 'Thumbnails/' + submitData.thumbnailName
        }
        console.log(submitData, '==================>')
        const method = 'PUT'
        const url = `exercises/editExercise/${exerciseId}`
        const headers = {
          authorization: localStorage.getItem('accessToken'),
        }
        request(appType, method, url, headers, submitData, dispatch, (data) => {
          Swal.close()
          Swal.fire({
            icon: "success",
            title: `övning ändrats!`,
          });
          refetch()
        })
      })
    }
  }
}

export default {
  setSelectedApplication,
  fetchExerciseList,
  addExercise,
  deleteExercise,
  editExercise,
};
