import React, { useState } from "react";
import { connect } from "react-redux";
import administratorAction from "../../store/actions/administratorAction";

import "./login.css";

import TopCornerRight from "./TopCornerRight";
import { ReactComponent as LoginImage } from "../../assets/images/login_image.svg";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (event) => {
    event.preventDefault();
    props.login(email, password);
  };

  return (
    <>
      <TopCornerRight />
      <div className="container py-5">
        <div className="row">
          <div className="col-md-5">
            <LoginImage
              style={{
                width: 520,
                height: 340,
              }}
            />
          </div>
          <div className="col-md-6 pt-5 px-5 ml-5">
            <div
              className="container px-0"
              id="form-container"
              style={{
                boxShadow: "none",
                height: "auto",
              }}
            >
              <h1
                style={{
                  fontWeight: "bold",
                  fontSize: 35,
                  lineHeight: "50px",
                }}
              >
                Administratör Login
              </h1>
              <form onSubmit={handleLogin}>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="E-post"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Lösenord"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="mt-5 d-flex flex-row justify-content-between">
                  <input type="submit" className="btn" value="Logga in" />
                  <span
                    style={{
                      fontSize: 15,
                      lineHeight: "22px",
                      cursor: "pointer",
                    }}
                  >
                    Glömt lösenord?
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isLogin: state.administrator.isLogin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password) =>
      dispatch(administratorAction.login(email, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
