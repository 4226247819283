import websiteAction from '../websiteAction'
import Swal from 'sweetalert2'

const errorHandler = (err, dispatch) => {
    console.log('this is err>>>>>>>>>>>>>',err)
    console.log(err.response.data.message)
    dispatch(websiteAction.setError('Error occured, please try again.'))
    Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.response.data.message || "Error occured, please try again."
    })
}

export default errorHandler