import React, { useState } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import axios from "../../utils/axios";
import trainingActions from "../../store/actions/trainingActions";
import request from '../../store/actions/helper/request'
import Swal from "sweetalert2";
import { connect } from "react-redux";

const SessionText = styled.span`
  font-weight: normal;
  font-style: normal;
  font-size: 15px;
  line-height: 22px;
`;

const ButtonAdd = styled.button`
  background-color: #fff;
  color: #930000;
  border: 1px solid #930000;
  font-weight: bold;
  font-style: normal;
  font-size: 11.5px;
  line-height: 16.5px;
  text-align: center;
  width: 88px;
  height: 18px;
  border-radius: 17px;
  outline: none;
`;

const ModalTitle = styled(Modal.Title)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18.2156px;
  line-height: 26px;
`;

const ButtonSubmit = styled.input`
  background-color: #930000;
  border-radius: 17px;
  color: #fff;
  width: 200px;
  height: 35px;
  outline: none;
`;

const SubSessionItemEnd = (props) => {

  const {
    stack,
    chosenLevel,
    session,
    sessionNum,
    exerciseList,
    refetch,
    postSubsession
  } = props

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [exerciseId, setExerciseId] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const submitData = {
      exercise: exerciseId,
    };
    Swal.fire({
      title: `Uploading..`,
      showConfirmButton: false,
    });
    Swal.showLoading()

    const url = `levels/${chosenLevel}/sessions/${session.id}/subsessions`
    postSubsession(url, submitData, refetch, chosenLevel, handleClose)
  };

  return (
    <>
      <div className="d-flex flex-row col-md-12 px-0">
        <div className="col-md-2 px-0">
          <SessionText>Session {`${sessionNum}.${stack}`}</SessionText>
        </div>
        <div className="col-md-7">
          <button type='button' onClick={handleShow} style={{
              backgroundColor: "#fff",
              color: "#930000",
              border: "1px solid #930000",
              fontWeight: "bold",
              fontStyle: "normal",
              fontSize: "11.5px",
              lineHeight: "16.5px",
              textAlign: "center",
              width: "88px",
              height: "18px",
              borderRadius: "17px",
              outline: "none",
          }}>Lägg till</button>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <ModalTitle>Lägg till övning i Sessionen</ModalTitle>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="">
                Session {`${sessionNum}.${stack}`}
                </label>
                <select
                  className="form-control"
                  defaultValue={exerciseId}
                  onChange={(e) => setExerciseId(e.target.value)}
                  required
                >
                  <option value="" disabled hidden>
                    Select
                  </option>
                  {exerciseList.map((exercise, i) => {
                    return (
                      <option key={i} value={exercise.id}>
                        {exercise.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="d-flex flex-row justify-content-center">
                <ButtonSubmit type="submit" value="Lägg till" />
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    exerciseList: state.exerciseList.exercises,
  };
};

const mapDispatchToProps = (dispatch, getState) => {
  return {
    refetch: (levelId) => {
      dispatch(trainingActions.fetchSessions(levelId))
    },
    postSubsession: (url, submitData, refetch, chosenLevel, handleClose) => {
      dispatch(trainingActions.postSubsessions(url, submitData, refetch, chosenLevel, handleClose))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubSessionItemEnd);
