import React from "react";
import { Route, withRouter, useRouteMatch } from "react-router-dom";

import WhiteLogo from "../assets/images/logo-white.png";
import { ReactComponent as StatisticIcon } from "../assets/images/icons/icon_statistic.svg";
import { ReactComponent as TrainingIcon } from "../assets/images/icons/icon_training.svg";
import { ReactComponent as AccountIcon } from "../assets/images/icons/icon_user.svg";

import AdminSidebarMenuItem from "../components/AdminSidebarMenuItem";

import Statistic from "../containers/statistic";
import Training from "../containers/training";
import Account from "../containers/account";

function AdministratorRouter(props) {
  let { path } = useRouteMatch();

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-md-2 d-flex flex-column align-items-center px-0"
            style={{
              backgroundColor: "#930000",
              minHeight: "100vh",
              paddingTop: 50,
              color: "#fff",
            }}
          >
            <img
              src={WhiteLogo}
              className="img-fluid"
              alt="YLVA White Logo"
              style={{ width: 60.92, height: 22, marginBottom: 28 }}
            />
            <div className="container flex-column d-flex px-0">
              <AdminSidebarMenuItem
                icon={StatisticIcon}
                text="Användarstatistik"
                target="/administrator"
              />
              <AdminSidebarMenuItem
                icon={TrainingIcon}
                text="Träningsscheman"
                target="/administrator/training"
              />
              <AdminSidebarMenuItem
                icon={AccountIcon}
                text="Kontoinställningar"
                target="/administrator/account"
              />
            </div>
          </div>
          <div className="col-md-10 px-5 py-5">
            <Route exact path={path}>
              <Statistic />
            </Route>
            <Route path={`${path}/training`}>
              <Training />
            </Route>
            <Route path={`${path}/account`}>
              <Account />
            </Route>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(AdministratorRouter);
