import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import trainingActions from "../../store/actions/trainingActions";
import exerciseListAction from '../../store/actions/exerciseListAction'

const Container = styled.div`
  box-shadow: 0px 0px 3.64313px rgba(0, 0, 0, 0.25);
  font-size
`;

const AppText = styled.span`
  color: #000;
  font-size: 18px;
  text-align: end;
`;

const Select = styled.select`
  color: #000;
  font-size: 18px;
  text-align: end;
`;

const SelectApplication = (props) => {
  const { selectApp, fetchLevels } = props;
  return (
    <div className="d-flex flex-row mb-5 container"
      style={{
        paddingRight: "0",
        paddingLeft: "0",
      }}
    >
      <Container className="col-md-5 d-flex align-items-center justify-content-between py-3">
        <AppText>App</AppText>
        <Select
          className="form-control col-md-6"
          onChange={(e) => {
            if (e.target.value === 'YLVA') {
              localStorage.setItem("appType", 'YLVA');
              selectApp(e.target.value)
              fetchLevels()
            } else {
              localStorage.setItem("appType", 'YLVA_GRAVID');
              selectApp(e.target.value)
              fetchLevels()
            }
          }}
        >
          <option value="YLVA">YLVA</option>
          <option value="YLVA_GRAVID">YLVA Gravid</option>
        </Select>
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectApp: (app) => {
      dispatch(exerciseListAction.setSelectedApplication(app))
    },
    fetchLevels: () => {
      dispatch(trainingActions.fetchLevels())
    },
  }
}

export default connect(null, mapDispatchToProps) (SelectApplication);
