import React from "react";
import { useHistory } from "react-router-dom";

const LogoutButton = (props) => {
  const history = useHistory();
  const logoutAction = () => {
    localStorage.clear();
    history.push("/");
  };

  return (
    <button
      style={{
        backgroundColor: "transparent",
        color: "#930000",
        borderRadius: 17,
        height: 35,
        width: 145,
        textAlign: "center",
        outline: 'none',
      }}
      onClick={logoutAction}
    >
      Logga Ut
    </button>
  );
};

export default LogoutButton;
