import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import statisticAction from '../../store/actions/statisticAction'

const HasChildrenOption = (props) => {

  const {
    hasChildren,
    fetchHasChildren
  } = props

  useEffect( () => {
    fetchHasChildren(1)
  },[])

  // useEffect( () => {
  //   if (hasChildren !== 0) {
  //     console.log(hasChildren)
  //   }
  // },[hasChildren])

  return (
    <div className="col-md-6 pl-0">
      <div
        className="d-flex flex-row-reverse justify-content-between align-items-center py-3 px-3"
        style={{
          boxShadow: "0px 0px 3.64313px rgba(0, 0, 0, 0.25)",
        }}
      >
        <span
          style={{
            fontWeight: 600,
            fontSize: 18,
          }}
        >
          {hasChildren}
        </span>
        <select
          onChange={(e) => fetchHasChildren(e.target.value)}
          className="form-control col-md-5"
          style={{
            color: "#000",
          }}
        >
          <option value={1}>Har barn</option>
          <option value={0}>Inga barn</option>
        </select>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    hasChildren: state.statistic.hasChildren
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchHasChildren: (isHaveChildren) => {
      dispatch(statisticAction.fetchHasChildren(isHaveChildren))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps) (HasChildrenOption);
