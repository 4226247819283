import React from "react";

import RoundBG from "../../components/RoundBG/Sect2BG"
import Steg1Img from "../../assets/images/tutorial_questions.png";
import Steg2Img from "../../assets/images/tutorial_exercise.png";
import Steg3Img from "../../assets/images/phone_3.png";
import ArrowIcon from "../../assets/images/icons/red-arrow.svg";


import "./section2.css"

export default function Section2(props) {
  return (
    <>
      <RoundBG />
      <div className="section2-container" id="steg">
        <div className="steg-container steg1">
          <img className="steg-img" src={Steg1Img} alt="Steg 1" />
          <h2 className="steg-title">
            Steg 1.
          </h2>
          <span className="steg-desc">
            Registrera dina besvär.
          </span>
        </div>
        <div className="arrow-container">
          <img className="arrow-icon" src={ArrowIcon} alt="" />
        </div>
        <div className="steg-container steg2">
          <img className="steg-img" src={Steg2Img} alt="Steg 2" />
          <h2 className="steg-title">
            Steg 2.
          </h2>
          <span className="steg-desc">
            Anpassad träning dill dig.
          </span>
        </div>
        <div className="arrow-container">
          <img className="arrow-icon" src={ArrowIcon} alt="" />
        </div>
        <div className="steg-container steg3">
          <img className="steg-img" src={Steg3Img} alt="Steg 3" />
          <h2 className="steg-title">
            Steg 3.
          </h2>
          <span className="steg-desc">
            Avancera på din bana mot bättre hälsä.
          </span>
        </div>
      </div>
    </>
  );
}
