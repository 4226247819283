import React from "react";
import { connect } from "react-redux";
import statisticAction from '../../store/actions/statisticAction'

const SelectAplication = (props) => {

  const {
    selectApp,
    fetchLevels
  } = props

  return (
    <div className="col-md-6 pl-0">
      <div
        className="d-flex flex-row justify-content-between align-items-center py-3 px-3"
        style={{
          boxShadow: "0px 0px 3.64313px rgba(0, 0, 0, 0.25)",
        }}
      >
        <span
          style={{
            fontWeight: 600,
            fontSize: 18,
          }}
        >
          App
        </span>
        <select
          onChange={(e) => {
            if (e.target.value === 'YLVA') {
              localStorage.setItem("appType", 'YLVA');
              selectApp(e.target.value)
              fetchLevels()
            } else {
              localStorage.setItem("appType", 'YLVA_GRAVID');
              selectApp(e.target.value)
              fetchLevels()
            }
          }}
          className="form-control col-md-5"
          style={{
            color: "#000",
          }}
        >
          <option value={'YLVA'}>YLVA</option>
          <option value={'YLVA_GRAVID'}>YLVA Gravid</option>
        </select>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectApp: (app) => {
      dispatch(statisticAction.selectApp(app))
    }
  }
}

export default connect(null, mapDispatchToProps) (SelectAplication);
