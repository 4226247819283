import React, { useEffect } from "react";

import TOSImage from "../../assets/images/undraw_tos.png";

const TOS = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main className="container">
        <div className="row">
          <div className="col-md-16 col-sm" id="article-container">
            <h1>Användarvillkor</h1>
            <article>
              <p>
                Vad kul att du vill använda Ylva/ YlvaGravid! Vi hoppas att du kommer att bli nöjd med tjänsten. Innan du börjar använda Ylva/YlvaGravid rekommenderar vi att du läser igenom användarvillkoren och integritetspolicyn så att du vet vad som gäller. Det som står i denna text är alltså avtalet mellan dig och oss.
                Genom att registrera ett konto i någon av applikationerna Ylva/ YlvaGravid eller på hemsidorna Ylva.app eller YlvaGravid.se godkänner du nedanstående användarvillkor och integritetspolicyn mellan dig och Ylva/YlvaGravid/  Healthonline Sweden AB (org.nr 559104-9282).
              </p>

              <p>
                Det sker kontinuerlig uppdatering av villkoren men den senaste versionen finns alltid tillgänglig i applikationerna och på hemsidan. Ändringar blir gällande så fort du tagit del av dem, eller senast 30 dagar efter att de publicerats på hemsidan.
              </p>

              <p>
                Observera att den information som tillhandahålls i applikationerna Ylva/ YlvaGravid inte utgör medicinsk rådgivning och att Health Online Sweden AB inte tar något medicinskt ansvar för ditt användande av applikationerna. Varje kvinna är unik och den information du får i applikationerna är generell. Rådgöra alltid med din barnmorska och/eller läkare om du är osäker.
              </p>

              <p>Vad är Ylva/YlvaGravid?</p>
              
              <p>
                I applikationerna Ylva och YlvaGravid tillhandahåller HealthOnline Sweden AB individanpassade träningsprogram för kvinnor med besvär från bäcken och bäckenbotten under och efter graviditet samt vid andra tillfällen i livet. HealthOnline Sweden AB tillhandahåller också hemsidor kopplade till applikationerna, Ylva.app samt YlvaGravid.se. I nedan text benämns samtliga som ”tjänsterna”.
              </p>

              <p>
                För att kunna använda applikationerna måste du registrera ett användarkonto med din email adress och lösenord. För att vi ska kunna tillhandahålla tjänsten och ge dig individanpassade träningsprogram måste du tillhandahålla viss information i enlighet med de instruktioner som ges i applikationerna. Bland annat måste du tillhandahålla information om  din graviditet inklusive beräknat förlossningsdatum eller när du födde barn. Du måste också registrera din vikt, ålder och hur mycket besvär du har. Dina uppgifter hanteras i enlighet med vår integritetspolicy.
              </p>

              <p>
                Applikationerna laddas ned kostnadsfritt från App Store och Google Play. I test-och gratisversionerna av applikationerna har du endast tillgång till ett begränsat antal träningsprogram.
              </p>

              <p>
                Om du har problem med detta kan du kontakta oss på info@ylva.app, info@ylvagravid.se eller info@healthonline.se.
              </p>

              <p>
                Om du accepterar ett erbjudande från en av våra samarbetspartners, ingår du ett separat avtal med sådan samarbetspartner, som kan innebära att du blir betalningsskyldig till samarbetspartnern.
              </p>

              <p>
                Statistikfunktionen i Ylva/YlvaGravid gör att du hela tiden har koll på din träning och dina framsteg.
              </p>
                
              <p>
                Mer information om Ylva samt YlvaGravid finns att tillgå i applikationerna och på hemsidorna Ylva.app samt YlvaGravid.se.
              </p>
                
              <p>
                Missbruk
              </p>
                
              <p>
                HealthOnline Sweden AB har alltid rätt att avsluta ditt användarkonto och/eller blockera din e-postadress. Så kan exempelvis ske om du på något sätt missbrukar någon av våra applikationer. Missbruk kan exempelvis bestå av brott mot Villkoren. Du är skyldig att ersätta HealthOnline Sweden AB för eventuella kostnader som missbruket resulterar i.
              </p>
                
              <p>
                Ändringar, uppdateringar och begränsad tillgång till Ylva/ YlvaGravid
              </p>  
                
              <p>
                HealthOnline Sweden AB förbehåller sig rätten att när som helst ändra det visuella uttrycket, och även i övrigt genomföra uppdateringar, i applikationerna och på hemsidorna. HealthOnline Sweden AB förbehåller sig även rätten att ändra, avbryta och/eller avsluta funktioner i sina tjänster inklusive – men inte begränsat till – funktionalitet, funktioner och tjänster, oavsett om förvarning om detta sker till dig eller inte. All ny funktionalitet, nya funktioner och/eller nya tjänster som lanseras i våra applikationer och hemsidor omfattas av användarvillkoren.
              </p>

              <p>
                HealthOnline Sweden AB förbehåller sig även rätten att när som helst, med eller utan förvarning, vidta åtgärder som påverkar din tillgång till Ylva/YlvaGravid om detta är nödvändigt med hänsyn till bland annat tekniska, underhålls- eller säkerhetsmässiga skäl. 
                HealthOnline Sweden AB ska vidta sådana åtgärder skyndsamt och på ett sätt som i minsta mån orsakar störningar för dig.
              </p>

              <p>
                HealthOnline Sweden AB:s utövande av ovanstående rättighet ger dig inte rätt att kräva någon ersättning.
              </p>
                
              <p>Immateriella rättigheter</p>

              <p>  
                HealthOnline Sweden AB och/eller eventuella samarbetspartners äger samtliga rättigheter till allt material i våra applikationer och på våra hemsidor såsom träningsprogram, bilder, filmer och lagrad information. HealthOnline Sweden äger också de immateriella rättigheterna hänförliga till våra applikationer och hemsidor, inkluderat, men ej begränsat till, upphovsrätt, patent, varumärken, firma, mönster, programvara, käll-kod, databaser, affärsplaner, design och know-how, oavsett om rättigheterna är registrerade eller inte. All dokumentation, inklusive manualer, användarguider, eller andra skriftliga, elektroniska eller icke-elektroniska, beskrivningar av hur applikationerna samt hemsidorna är upprättade och används, anses vara en del av HealthOnline Sweden och är därmed föremål för samma restriktioner. Inget i villkoren ska tolkas så att någon av dessa rättigheter helt eller delvis överlåts till dig.
                Applikationerna får bara användas privat av den registrerade användaren och inte delas eller ställas till allmänhetens förfogande. Det är inte tillåtet att överlåta, offentliggöra, licensera eller sälja applikationerna, hemsidorna eller deras innehåll till tredje part eller att på något sätt utnyttja tjänsterna kommersiellt. Det är inte heller tillåtet att översätta tjänsterna, skapa arbeten som härletts från tjänsterna eller försöka ta reda på applikationernas källkod.
              </p>

              <p>
                Du är fortsatt ägare av all data som ägs av dig och som du överför till HealthOnline Sweden AB genom våra applikationer eller hemsidor. HealthOnline Sweden AB gör inte anspråk på immateriella rättigheter, eller äganderätt av något slag, till sådan data. För övriga information om hur vi hanterar dina uppgifter och din data hänvisar vi till vår integritetspolicy.
              </p>
                
              <p>Support, kommunikation och kontaktuppgifter</p>
                
              <p>
                Du accepterar att all kommunikation mellan Ylva, YlvaGravid och HealthOnline Sweden AB och dig relaterat till applikationerna och hemsidorna får ske elektroniskt genom e-post info@ylva.app, info@ylvagravid.se eller via info@healthonline.se Exempel på meddelanden är information om driftstörningar, nya versioner, övrig information om applikationerna samt support.
              </p>

              <p>
                Ansvarsbegränsning
              </p>

              <p>
                Applikationerna Ylva/YlvaGravid samt hemsidorna Ylva.app/YlvaGravid.se levereras i befintligt skick och HealthOnline Sweden AB ger inte några garantier vare sig uttryckligen eller underförstått för deras funktionalitet eller lämpligheten av deras innehåll för dig. HealthOnline Sweden AB tar inte ansvar för eventuella fysiska och/eller psykiska skador som kan uppkomma i samband med användning av våra tjänster 
                Våra tjänster är utvecklade för svenska förhållanden och för användning i Sverige.  Vi rekommenderar användare att göra en fullständig hälsoundersökning innan man börjar använda våra tjänster och att man omedelbart kontaktar hälso-och sjukvård vid symtom såsom, men ej begränsat till, yrsel, andnöd, bröstsmärtor, domningar, onormal trötthet eller andra symtom som uppkommer i samband med användning av tjänsterna, eller som kan hänföras till sådan användning. I anslutning till detta skall användaren omedelbart sluta använda tjänsten. 
              </p>
              <p>
                Genom att använda någon av våra tjänster accepterar du dessa villkor i sin helhet och är införstådd med att du använder tjänsterna på egen risk. Du accepterar också att det finns en risk för skada i samband med all fysisk aktivitet och att du beviljar HealthOnline Sweden AB och dess ledning, representanter och anställda fullständig ansvarsfrihet för alla skador som kan uppkomma till följd av din användning av våra tjänster inklusive, men ej begränsat till fysiska och psykiska skador, sjukdom, död, inkomstbortfall och förlust av data.
              </p>
              <p>
                Du är medveten om att tillgång till internet krävs för att tillgå och använda vissa delar av applikationerna och hemsidorna och att sådan tillgång varken installeras, underhålls eller etableras av HealthOnline Sweden AB. Därmed kan HealthOnline Sweden AB inte garantera säker anslutning till applikationerna och hemsidorna.
              </p>

              <p>HealthOnline Sweden AB är under inga omständigheter skyldigt att tillhandahålla applikationerna Ylva/ YlvaGravid eller hemsidorna Ylva.app samt YlvaGravid.se för dig.</p>
                
              <p>Force majeure</p>
                
              <p>Med undantag för de fall HealthOnline Sweden AB eller du har agerat grovt vårdslöst är HealthOnline Sweden AB respektive du alltid befriad från ansvar för brott mot användarvillkoren  samt integritetspolicyn som orsakats av händelser utanför HealthOnline Sweden AB:s respektive din kontroll, såsom, men inte begränsat till, åsknedslag, eldsvåda, strömavbrott, strejk, krig, mobilisering eller militärinkallelse av större omfattning, rekvisition, beslag, myndighetsbestämmelser, myndighetsåtgärd, nytillkommen eller ändrad lagstiftning, konflikt på arbetsmarknaden och därmed jämställda omständigheter.</p>
                
              <p>Ändring av villkoren</p>
                
              <p>Health Online Sweden AB förbehåller sig rätten att när som helst ändra eller göra tillägg till användarvillkoren. HealthOnline Sweden AB avgör när sådana eventuella ändringar och/eller tillägg ska träda i kraft. Uppgifter om villkorsändringar ska alltid på ett tydligt sätt framgå för dig genom våra applikationer eller hemsidor.</p>

              <p>Du har alltid rätt att avsluta ditt Användarkonto i det fall om inte samtycker till sådana villkorsändringar som avses i punkten ovan. Om du fortsätter att använda någon av våra applikationer eller hemsidor efter att ändringar eller tillägg trätt i kraft, anses du bunden av de nya eller ändrade villkoren.</p>
              
              <p>
                Tillämplig lag och tvistelösning
                På användarvillkoren och integritetspolicyn ska svensk rätt tillämpas såvida tvingande lag inte föreskriver annorlunda.
              </p>

              <p>Tvister som uppstår med anledning av användarvillkoren eller i övrigt med anledning av ditt användande av HealthOnline Sweden ABs applikationer eller hemsidor ska avgöras i allmän domstol, varvid Stockholms tingsrätt ska vara första instans.</p>
                
              <p>Kontakt</p>
              
              <p>
                Du kontaktar oss på någon av mejladresserna
                info@healthonline.se
                info@ylva.app
                info@ylvagravid.se
              </p> 
              <p>Stockholm 2020-11-10</p>
            </article>
          </div>
          {/* <div className="col-12 col-sm-12 col-md-4" id="sidebar-container">
            <img className="img-fluid" src={TOSImage} alt="Term Of Service" />
          </div> */}
        </div>
      </main>
    </>
  );
};

export default TOS;
