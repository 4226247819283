import axios from 'axios'
import errorHandler from './errorHandler'

const request = async (appType, method, url, headers, data, dispatch, callback) => {

    const localConfig = {
        method: method,
        url: `http://localhost:3000/v1/${url}`,
        headers: headers,
        data: data,
    }
    const defaultConfig = {
        method: method,
        url: `https://dev.ylva.app/api/v1/${url}`,
        headers: headers,
        data: data,
    }
    const mamaConfig = {
        method: method,
        url: `https://dev.ylva.app/mama/api/v1/${url}`,
        headers: headers,
        data: data,
    }

    headers.authorization = localStorage.getItem('accessToken')
    axios(appType == 'YLVA_GRAVID' ? mamaConfig : defaultConfig)
    // axios(localConfig)
    .then(({data}) => {
        callback(data)
    })
    .catch((err) => {
        errorHandler(err, dispatch)
    })
}

export default request