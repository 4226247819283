import React, { useEffect } from "react";
import { connect } from "react-redux";
import statisticAction from "../../store/actions/statisticAction";

const AvgAge = (props) => {

  const {
    selectedApp,
    avgAge,
    getAvgAge
  } = props

  useEffect(() => {
    getAvgAge()
  }, [selectedApp])

  return (
    <div className="col-md-6 pl-0">
      <div
        className="d-flex flex-row justify-content-between align-items-center py-3 px-3"
        style={{
          boxShadow: "0px 0px 3.64313px rgba(0, 0, 0, 0.25)",
        }}
      >
        <span
          style={{
            fontWeight: 600,
            fontSize: 18,
          }}
        >
          Snittålder
        </span>
        <span
          style={{
            fontWeight: 600,
            fontSize: 18,
            color: "#930000",
          }}
        >
          {!avgAge ? 'loading..' : avgAge === 'NaN' ? 0 : `${avgAge} år`} 
          
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    avgAge: state.statistic.avgAge,
    selectedApp: state.statistic.selectedApplication,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAvgAge: () => {
      dispatch(statisticAction.fetchAvgAge())
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps) (AvgAge);
