import React, {useState} from "react";
import { Spinner } from "react-bootstrap";
import RoundBG from "../../components/RoundBG/Sect3BG"
import ExerciseImg from "../../assets/images/undraw_personal_training.png";
import axios from "axios"
import "./section3.css"

import swal from "sweetalert2";

export default function Section3(props) {

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  // Send Email from admin
  const submitHandler = (e) => {
    e.preventDefault()
    if (!name || !email || !message) {
      swal.fire({
        title: "Missing input!",
        icon: "error",
        text: " Please input name, email dan message."
      })
    } else {
      swal.showLoading()
      axios({
        method: 'post',
        url: 'https://dev.ylva.app/api/v1/admin/mail',
        data: {
          name,
          email,
          message
        }
      }).then((data) => {
          swal.close()
          swal.fire({
            title: "success",
            icon: "success"
          }).then(() => {
            setName('')
            setEmail('')
            setMessage('')
          })
      }).catch((err) => {
        console.log(err)
      })
    }
  }

  return (
    <>
      <RoundBG />
      <div className="section3-container" id="kom-med-i-ylva">
        <div className="description-container">
          <img className="desc-img" src={ExerciseImg} alt=""/>
          <h2 className="section-title">
            Kom med i Ylva!
          </h2>
          <span className="desc">
            Vill du vara med och påverka? Var först att testa och förbättra.
            Vi vill veta din åsikt. Vi bygger ett community där alla kvinnor kan dela sina erfarenheter och tips.
          </span>
        </div>
        <div className="form-container">
          <form className="section-form" onSubmit={ (e) => submitHandler(e)}>
            <h2 className="form-title">
              Kom med i Ylva!
            </h2>
            <input required className="form-input" placeholder="Namn" onChange={(e) => {setName(e.target.value);}}/>
            <input required className="form-input" placeholder="Epost" onChange={(e) => {setEmail(e.target.value);}} type="email"/>
            <textarea required className="form-textarea" placeholder="Meddelande" maxLength="255" onChange={(e) => {setMessage(e.target.value);}} />
            <div className="checkbox-and-desc-container">
              <div className="checkbox-container">
                <input required className="form-checkbox" type="checkbox" />
              </div>
              <span className="form-checkbox-desc">
                Jag godkänner att dela med mig av mina kontaktuppgifter.
              </span>
            </div>
            <button className="form-submit-button" type="submit" style={{outline: "none"}}>Skicka</button>
          </form>
        </div>
      </div>
    </>
  );
}
