import React, {useEffect} from "react";
import { connect } from 'react-redux'
import statisticAction from "../../store/actions/statisticAction";



const UserData = (props) => {

  const {
    selectedApp,
    totalUser,
    fetch,
    levels,
  } = props
  
  useEffect(() => {
    fetch()
  }, [selectedApp, levels])

  // useEffect(() => {
  //     console.log(typeof totalUser)
  // }, [totalUser])

  // useEffect(() => {
  //   dispatch(statistic.fetchStatistic())
  // }, [dispatch])

  return (
    <div className="col-md-6 pl-0">
      <div
        className="d-flex flex-row justify-content-between align-items-center py-3 px-3"
        style={{
          boxShadow: "0px 0px 3.64313px rgba(0, 0, 0, 0.25)",
        }}
      >
        <span
          style={{
            fontWeight: 600,
            fontSize: 18,
          }}
        >
          Användare
        </span>
        <span
          style={{
            fontWeight: 600,
            fontSize: 18,
            color: "#930000"
          }}
        >
          {totalUser >= 0 ? totalUser : 'loading'}
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    // isLogin: state.administrator.isLogin,
    totalUser: state.statistic.totalUser,
    selectedApp: state.statistic.selectedApplication,
    levels: state.statistic.levels,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetch: () => {
      dispatch(statisticAction.fetchTotalUser())
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps) (UserData);
